@import "lexus-style-guide/colors/index";

.error-card {
    background-color: transparent;

    > :not(:last-child) {
        margin-bottom: 2.4rem;
    }

    & &-button {
        box-sizing: border-box;
        width: 100%;
    }

    & &-case-number,
    & &-fine-print,
    & &__title {
        color: lx-color("deep-blue");
    }

    & &-lcac {
        &__description {
            margin-bottom: 2rem;
        }

        &__phone-button {
            width: 100%;
            box-sizing: border-box;
        }
    }

    & &__title:not(:last-child) {
        margin-bottom: 1.4rem;
    }
}
