@import "../../colors/index";
@import "../../typography/index";
@import "./variables";

.lxs-main-nav {
    color: lx-color("white");
    background-image:
        linear-gradient(
            180deg,
            lx-color-transparent($color: $lxs-main-nav-gradient-color, $opacity: 0.85) 0,
            lx-color-transparent($color: $lxs-main-nav-gradient-color, $opacity: 0.541) 34%,
            lx-color-transparent($color: $lxs-main-nav-gradient-color, $opacity: 0.382) 47%,
            lx-color-transparent($color: $lxs-main-nav-gradient-color, $opacity: 0.278) 56.5%,
            lx-color-transparent($color: $lxs-main-nav-gradient-color, $opacity: 0.126) 73%,
            lx-color-transparent($color: $lxs-main-nav-gradient-color, $opacity: 0.075) 80.2%,
            lx-color-transparent($color: $lxs-main-nav-gradient-color, $opacity: 0.021) 91%,
            lx-color-transparent($color: $lxs-main-nav-gradient-color, $opacity: 0.002) 98.2%,
            lx-color-transparent($color: $lxs-main-nav-gradient-color, $opacity: 0)
        );
    padding-bottom: $lxs-main-nav-padding-bottom;

    @include lxs-screen(mobile) {
        transition: all 500ms ease-in-out;
        transition-property: background-image, background-color;

        &#{&}--dark {
            background-color: $lx-blue-opaque-95;
            background-image:
                linear-gradient(
                    to bottom,
                    lx-color-transparent("black", "default", 0.8) 10%,
                    lx-color-transparent("black", "default", 0) 50%
                );
        }
    }
}
