@import "../../colors/index";
@import "../../typography/index";

.lxs-sticker {
    color: lx-color("deep-blue", "light");
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 50px;

    &--round {
        align-items: center;
        color: lx-color("smoke");
        background-color: lx-color("deep-blue", "light");
        height: 64px;
        width: 64px;
        border-radius: 100px;
    }

    &--alt-bg {
        background-color: lx-color("iron", "dark");
    }

    &--round &__text {
        margin-top: -5px;
        align-items: center;
    }

    &__text {
        display: flex;
        flex-direction: column;
        align-items: left;
        justify-content: center;
    }

    &__line {
        font-size: 9px;
        line-height: 13px;
        text-transform: uppercase;
        letter-spacing: lx-letter-spacing("tiny");
        font-weight: 400;

        &--strong {
            font-size: 12px;
            font-weight: bold;
            letter-spacing: lx-letter-spacing("small");
        }
    }
}
