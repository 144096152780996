@import "lexus-style-guide/utilities/mixins";

/* SVG spinner icon animation */
.spinner {
    z-index: 2;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -25px 0 0 -25px;
    width: 48px;
    height: 48px;
    animation: rotate 2s linear infinite;

    &.custom-small {
        width: 24px;
        height: 24px;
        position: relative;
        top: 0;
        left: 0;
        margin: 0;
    }
}

.spinner .path {
    stroke: #ca4b19;
    animation: dash 1.5s ease-in-out infinite;
}

@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes dash {
    0% {
        stroke-dasharray: 1, 150;
        stroke-dashoffset: 0;
    }

    50% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -35;
    }

    100% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -124;
    }
}
