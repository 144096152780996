@import "../../utilities/mixins";

%lxs-focalpoint-image,
.lxs-focalpoint-image {
    @mixin cover {
        width: 100%;
        height: 100%;
        object-fit: cover;
        left: initial !important;
        top: initial !important;
        transform: none !important;
    }

    position: absolute;
    width: auto;
    height: auto;
    max-width: none;
    max-height: none;
    min-height: 100%;
    min-width: 100%;

    &--cover {
        @include cover;
    }

    @include lxs-screen(mobile) {
        @include cover;
    }
}
