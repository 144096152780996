.lxs-set-password-success {
    &__title--no-senkei {
        text-align: left;

        &::after {
            display: none;
        }
    }

    button#{&}__button {
        box-sizing: border-box;
        margin-top: 0.8rem;
        width: 100%;
    }
}
