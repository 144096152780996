@import "../variables";
@import "../../../utilities/mixins";

.lxs-textarea-field {
    display: inline-block;
    width: 100%;

    &__input {
        position: relative;
        width: 100%;
    }

    &__input-element {
        padding:
            #{$lxs-form-padding-vertical + 0.1rem}
            $lxs-form-padding-horizontal
            #{$lxs-form-padding-vertical - 0.1rem}
            $lxs-form-padding-horizontal;
        font-size: 1.9rem;
        font-weight: 300;
        background-color: transparent;
        border: none;
        width: 100%;
        resize: none;

        &:focus {
            outline: none;
        }
    }

    &--error & {
        &__border {
            border-color: lx-color("error");
        }

        &__label {
            color: lx-color("error");
        }
    }

    @include lxs-background-light {
        &__clear {
            color: $lxs-form-color-light-bg;
        }

        &__input-element {
            color: $lxs-form-text-color-light-bg;
        }
    }

    @include lxs-background-dark {
        &__clear {
            color: $lxs-form-color-dark-bg;
        }

        &__input-element {
            color: $lxs-form-text-color-dark-bg;
        }
    }
}
