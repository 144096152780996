@import "lexus-style-guide/colors/index";

.supported-browsers {
    &__page {
        position: fixed;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        flex-flow: column;
    }

    &__logo {
        margin-top: 4rem;
    }

    &__title {
        font-size: 3.2rem;
        line-height: 3.2rem;
        margin: 18.5rem 0 0;
        letter-spacing: 0.2rem;
        text-transform: uppercase;
    }

    &__description {
        margin: 2.4rem 0 8rem;
        font-size: 1.9rem;
        line-height: 3.2rem;
    }

    &__container:hover &__browser:not(:hover) {
        opacity: 0.5;
        margin: 0 2rem;
    }

    &__browser {
        display: inline-block;
        margin: 0 2rem;
        border-radius: 0.3rem;
        background-color: lx-color("deep-blue", "light");
        transition: transform 0.5s;
        box-shadow: 0 2.3rem 2.4rem -1.6rem rgba(0, 0, 0, 0.2);

        &:hover {
            transform: translateY(-0.5rem);
        }

        &,
        &:link,
        &:visited,
        &:hover,
        &:active {
            text-decoration: none;
        }
    }

    &__icon {
        width: 7.5rem;
        height: 7.5rem;
        margin: 2.5rem 5rem 0;
    }

    &__text {
        display: flex;
        font-size: 1.2rem;
        line-height: 1.3rem;
        letter-spacing: 0.15rem;
        font-weight: bold;
        text-transform: uppercase;
        justify-content: center;
        align-items: center;
        margin: 2rem 0;
    }

    &__chevron {
        margin-left: 0.7rem;
    }
}
