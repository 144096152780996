@import "../typography/mixins";
@import "../utilities/mixins";
@import "../colors/index";

@mixin plain-list-item {
    color: inherit;
    padding-left: 0;
    margin-bottom: 0;
    text-indent: 0;

    &::before {
        content: none;
        display: inline;
        text-indent: 0;
        width: auto;
    }
}

@mixin plain-list($li-selector: "> li") {
    margin-bottom: 0;
    padding-left: 0;

    @if $li-selector {
        #{$li-selector} {
            @include plain-list-item;
        }
    }
}

@mixin lx-tg-list {
    color: lx-color("deep-blue", "light");
    line-height: 30px;
    list-style: none;
    margin-bottom: 20px;
}

@mixin lx-tg-list-item-base {
    @include font-smoothing;

    font-family: $lx-font-main;
    font-size: 19px;
    font-weight: 300;
    padding-left: 30px;
    text-indent: -30px;
    margin-bottom: 8px;

    &::before {
        display: inline-block;
        text-indent: 0;
        width: 30px;
    }

    @include screen(phone) {
        line-height: 32px;
        font-size: 19px;
    }
}

@mixin lx-tg-unordered-list-item {
    &::before {
        content: "● ";
    }
}

@mixin lx-tg-ordered-list-item {
    counter-increment: ordered-list;

    &::before {
        content: counter(ordered-list) ". ";
    }
}

@mixin lx-tg-ol {
    counter-reset: ordered-list;
    list-style-type: none;
}

@mixin lx-tg-ordered-list($li-selector: "> li") {
    @include lx-tg-list;

    counter-reset: ordered-list;

    #{$li-selector} {
        @include lx-tg-list-item-base;
        @include lx-tg-ordered-list-item;
    }
}

@mixin lx-tg-unordered-list($li-selector: "> li") {
    @include lx-tg-list;

    #{$li-selector} {
        @include lx-tg-list-item-base;
        @include lx-tg-unordered-list-item;
    }
}
