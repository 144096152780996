@mixin transition-to-right {
    .lxs-svgicon-link__icon-left-to-right--right {
        opacity: 1;
    }

    .lxs-svgicon-link__icon-left-to-right--left {
        opacity: 0;
    }

    .lxs-svgicon-link__icon-left-to-right--text {
        transform: translateX(-11px);
    }
}
