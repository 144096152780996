@import "./variables";
@import "../../styles/variables";

@mixin layout-desktop {
    @media (min-width: max($desktop-breakpoint, $breakpoint)) {
        @content;
    }
}

@mixin layout-desktop-percent {
    @media (min-width: max($desktop-percent-breakpoint, $breakpoint)) {
        @content;
    }
}

@mixin layout-desktop-right-max {
    @media (min-width: $right-max-width-breakpoint) {
        @content;
    }
}
