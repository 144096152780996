@import "lexus-style-guide/colors/index";
@import "../../../../styles/mixins";

.stripe-card-detail {
    padding: 1.2rem 1.6rem 1.2rem 1.6rem;
    border: 0.1rem solid lx-color-transparent("smoke", "light", 0.5);
    border-radius: 0.3rem;
    transition: border 300ms;
    font-size: 2rem;
    color: lx-color("smoke");
    display: flex;
    position: relative;
    font-family: Nobel, Arial, sans-serif;
    cursor: pointer;

    &__card-image {
        margin-right: 1.2rem;
        height: 2rem;
    }

    &__text {
        margin: 0;
        line-height: 2.4rem;
    }

    &__card-last4-digit {
        margin-left: 0.8rem;
    }

    &__expire {
        position: absolute;
        top: 25%;
        right: 5%;
    }
}
