@import "../../colors/index";
@import "../../typography/variables";
@import "../../utilities/index";

.lxs-logo {
    color: lx-color("white");
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;

    &__image {
        @include lxs-animation(lxs-fade-in, 1, 2);
    }
}
