@import "../../Form/variables";
@import "../../../utilities/mixins";
@import "../../../forms/mixins";

.lxs-login-form {
    &__text-field {
        margin-bottom: 3.2rem;

        span {
            font-size: 1.7rem;
        }
    }

    p#{&}__header-text {
        @include lxs-background-dark {
            color: $lxs-form-label-color-dark-bg;
        }
    }

    &__header {
        &-title--no-senkei {
            text-align: left;

            &::after {
                display: none;
            }
        }

        &-link {
            &:hover {
                color: $lxs-form-border-color-light-bg;
            }

            @include lxs-background-dark {
                color: $lxs-form-label-color-dark-bg;

                &:hover {
                    color: $lxs-form-border-color-dark-bg;
                }
            }
        }

        @include lxs-screen(desktop) {
            &-text {
                font-size: 1.9rem;
            }
        }
    }

    &__footer {
        display: flex;
        justify-content: space-between;

        &-link {
            @include lxs-background-dark {
                color: $lxs-form-label-color-dark-bg;

                &:hover {
                    color: $lxs-form-border-color-dark-bg;
                }
            }

            font-size: 1.7rem;
            flex-direction: row;

            &:hover {
                color: $lxs-form-border-color-light-bg;
            }
        }
    }

    button#{&}__button {
        box-sizing: border-box;
        margin-top: 4rem;
        width: 100%;
    }

    &__toast-button {
        display: flex;
        margin-top: 1.6rem;
        box-sizing: border-box !important;
        width: 100%;
        max-width: 360px;
        justify-content: center;
    }

    &__toast-text {
        display: flex;
        flex-direction: column;
    }
}
