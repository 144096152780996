@import "../../colors/index";
@import "../../typography/index";
@import "../../utilities/index";

.lxs-svg-icon-button {
    font-family: $lx-font-main;
    background: none;
    border: 0;
    display: flex;
    align-items: center;
    cursor: pointer;
    outline: 0;
    transition: all 500ms;

    rect,
    path {
        transition: all 500ms;
    }

    &--theme-dark {
        color: lx-color("white");

        rect,
        path {
            fill: lx-color("white");
        }

        &:hover {
            color: lx-color("smoke");

            rect,
            path {
                fill: lx-color("smoke");
            }
        }

        .lxs-svg-icon--has-background {
            background: lx-color-transparent("black", "absolute", 0.25);
        }
    }

    &--theme-light {
        color: lx-color("black");

        rect,
        path {
            fill: lx-color("black");
        }

        &:hover {
            color: lx-color("deep-blue", "light");

            rect,
            path {
                fill: lx-color("deep-blue", "light");
            }
        }
    }

    &--icon-left {
        flex-flow: row-reverse;
    }

    &--stack-on-mobile {
        @include lxs-screen(mobile) {
            flex-flow: column-reverse;

            .lxs-svg-icon--has-background {
                padding: 0;
                background: none;
            }
        }
    }

    &__title {
        text-transform: uppercase;
        font-size: 11px;
        font-weight: 400;
        letter-spacing: lx-letter-spacing("small");
        padding: 0.5rem;

        @include lxs-screen(mobile) {
            .lxs-svg-icon-button--stack-on-mobile & {
                padding: 0;
                font-size: 10px;
                letter-spacing: 0;
            }
        }

        @include lxs-screen(desktop) {
            font-size: lx-font-size("h6");
            font-weight: normal;
            padding: 0.7rem;
        }
    }

    .lxs-svg-icon--has-background {
        @include lxs-screen(desktop) {
            height: 44px;
            width: 44px;
            padding: 0;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}
