@use "sass:string";
@import "../typography/mixins";
@import "../utilities/mixins";

@mixin icon-style {
    display: inline-block;
    font-family: icomoon;
    font-size: 32px;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    line-height: 1;
    text-transform: none;
}

$icon-set-selectors: "" !default;
$icon-set-selectors-before: "" !default;
$icon-set-selectors-after: "" !default;

@mixin icon-set($set: before, $name: "") {
    $icon-set-selectors: $icon-set-selectors + "#{&}, " !global;

    @if $set == before {
        $icon-set-selectors-before: $icon-set-selectors-before + "#{&}, " !global;
    } @else if $set == after {
        $icon-set-selectors-after: $icon-set-selectors-after + "#{&}, " !global;
    }
}

@mixin icon-render {
    #{$icon-set-selectors-before} {
        &::before {
            @include icon-style;
        }

        @include hover-set($pseudo-element: before) {
            color: inherit;
        }
    }

    #{$icon-set-selectors-after} {
        &::after {
            @include icon-style;
        }

        @include hover-set($pseudo-element: after) {
            color: inherit;
        }
    }

    #{$icon-set-selectors} {
        @include font-smoothing;

        color: inherit;
        text-decoration: none;

        &.is-with-externals {
            display: table-cell;
        }
    }
}

@mixin icon-load($name, $code, $position: before) {
    /* stylelint-disable-next-line string-quotes */
    $withslash: "'\\#{$code}'";

    @if $position == before {
        %icon-#{$name},
        .icon-#{$name} {
            @include icon-set($name: $name);

            &::before {
                content: string.unquote($withslash);
            }
        }
    } @else if $position == after {
        %icon-#{$name}-after {
            @include icon-set(after, $name: $name);

            &::after {
                content: string.unquote($withslash);
            }
        }
    }
}

@mixin icon-factory($parent, $icons) {
    @each $icon in $icons {
        .#{$parent}__icon-#{$icon} {
            @extend %icon-#{$icon};
            @content;
        }
    }
}
