@import "../variables";
@import "../../../utilities/mixins";

.lxs-notch-border-and-label {
    $base: &;

    &__label {
        position: absolute;
        top: 50%;
        left: $lxs-form-padding-horizontal;
        max-width: calc(100% - #{$lxs-form-padding-horizontal * 2});
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        transform: translateY(-50%);
        pointer-events: none;
        font-size: 1.3rem;
        letter-spacing: 0.15rem;
        font-weight: 300;
        transition-property: top, left, transform, color;
        transition-duration: 300ms;
        text-transform: uppercase;
        transform-origin: left;
    }

    &__border {
        position: absolute;
        top: -0.5rem;
        left: 0;
        right: 0;
        bottom: 0;
        border-width: $lxs-form-border-width;
        border-style: solid;
        border-radius: 0.3rem;
        pointer-events: none;
        transition-property: padding, border-color;
        transition-duration: 300ms;

        legend {
            transition: width 300ms;
        }
    }

    &--active & {
        &__label {
            top: 0;
            left: $lxs-form-notch-left + $lxs-form-notch-padding;
            transform: translateY(-50%) scale($lxs-form-label-scale);
        }

        &__border {
            // - .1rem, shouldnt be there but looks more correct.
            padding-left: $lxs-form-notch-left - 0.1rem;
        }
    }

    @include lxs-background-light {
        &__label {
            color: $lxs-form-label-color-light-bg;
        }

        &__border {
            border-color: $lxs-form-border-color-light-bg;
        }

        &--focus {
            #{$base}__label {
                color: $lxs-form-label-color-focus-light-bg;
            }

            #{$base}__border {
                border-color: $lxs-form-border-color-focus-light-bg;
            }
        }

        &--valid {
            #{$base}__border {
                border-color: lx-color("success");
            }

            #{$base}__label {
                color: lx-color("success");
            }
        }

        &--error {
            #{$base}__border {
                border-color: lx-color("error");
            }

            #{$base}__label {
                color: lx-color("error");
            }
        }
    }

    @include lxs-background-dark {
        &__label {
            color: $lxs-form-label-color-dark-bg;
        }

        &__border {
            border-color: $lxs-form-border-color-dark-bg;
        }

        &--focus {
            #{$base}__label {
                color: $lxs-form-label-color-focus-dark-bg;
            }

            #{$base}__border {
                border-color: $lxs-form-border-color-focus-dark-bg;
            }
        }

        &--valid {
            #{$base}__border {
                border-color: lx-color("success");
            }

            #{$base}__label {
                color: lx-color("success");
            }
        }

        &--error {
            #{$base}__border {
                border-color: lx-color("error");
            }

            #{$base}__label {
                color: lx-color("error");
            }
        }
    }
}
