@import "../../../../colors/index";
@import "../../../../typography/index";

.lxs-models-menu-mobile-vehicles {
    &--carousel {
        overflow-x: scroll;
        overflow-y: hidden;
        white-space: nowrap;

        .lxs-models-menu-mobile-vehicle-container {
            width: 270px;
            height: 250px;
            background-color: lx-color("smoke", "default");
            margin-right: $lxs-grid-gutter-width-mobile;
            display: inline-block;
            font-size: 20px;
            overflow: hidden;
            white-space: pre-wrap;
            border-radius: 5px;

            &__element {
                text-decoration: none;
            }

            &:first-child {
                margin-left: $lxs-grid-margin-width-mobile;
            }

            &:last-child {
                margin-right: $lxs-grid-margin-width-mobile;
            }
        }
    }

    &--block {
        .lxs-models-menu-mobile-vehicle-container {
            width: calc(100vw - #{$lxs-grid-margin-width-mobile * 2});
            height: calc(100vw - #{$lxs-grid-margin-width-mobile * 2});
            background-color: lx-color("smoke", "default");
            margin-left: $lxs-grid-margin-width-mobile;
            margin-right: $lxs-grid-margin-width-mobile;
            margin-bottom: $lxs-grid-margin-width-mobile;
            font-size: 20px;
            overflow: hidden;
            border-radius: 5px;

            &__element {
                text-decoration: none;
            }

            &-last {
                margin-bottom: 0;
            }
        }
    }

    &--hidden {
        display: none;
    }
}
