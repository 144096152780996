@import "../../../styles/module/index";
@import "lexus-style-guide/colors/index";
@import "lexus-style-guide/utilities/index";

.secondary-navigation-link {
    $lx-datauri-inari: url('data:image/gif;base64,R0lGODlhAQABAIAAAMtIAAAAACH/C1hNUCBEYXRhWE1QPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4gPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNS42LWMxNDUgNzkuMTYzNDk5LCAyMDE4LzA4LzEzLTE2OjQwOjIyICAgICAgICAiPiA8cmRmOlJERiB4bWxuczpyZGY9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkvMDIvMjItcmRmLXN5bnRheC1ucyMiPiA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtbG5zOnhtcE1NPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvbW0vIiB4bWxuczpzdFJlZj0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL3NUeXBlL1Jlc291cmNlUmVmIyIgeG1wOkNyZWF0b3JUb29sPSJBZG9iZSBQaG90b3Nob3AgQ0MgMjAxOSAoTWFjaW50b3NoKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDowOTRCNTJCMENFODUxMUU4QUQ4QkFBMTUxQkI5RTE5MCIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDowOTRCNTJCMUNFODUxMUU4QUQ4QkFBMTUxQkI5RTE5MCI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjA5NEI1MkFFQ0U4NTExRThBRDhCQUExNTFCQjlFMTkwIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjA5NEI1MkFGQ0U4NTExRThBRDhCQUExNTFCQjlFMTkwIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+Af/+/fz7+vn49/b19PPy8fDv7u3s6+rp6Ofm5eTj4uHg397d3Nva2djX1tXU09LR0M/OzczLysnIx8bFxMPCwcC/vr28u7q5uLe2tbSzsrGwr66trKuqqainpqWko6KhoJ+enZybmpmYl5aVlJOSkZCPjo2Mi4qJiIeGhYSDgoGAf359fHt6eXh3dnV0c3JxcG9ubWxramloZ2ZlZGNiYWBfXl1cW1pZWFdWVVRTUlFQT05NTEtKSUhHRkVEQ0JBQD8+PTw7Ojk4NzY1NDMyMTAvLi0sKyopKCcmJSQjIiEgHx4dHBsaGRgXFhUUExIREA8ODQwLCgkIBwYFBAMCAQAAIfkEAAAAAAAsAAAAAAEAAQAAAgJEAQA7');

    &.link-uppercase {
        color: lx-color("white");
        white-space: nowrap;
        height: 3.8rem;
        line-height: 3.8rem;
        vertical-align: center;
        position: relative;

        &::after {
            content: "";
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: 0;
            width: 2.5rem;
            border-bottom: 1px solid lx-color("white");
            opacity: 0;
            transition: all 0.3s ease-in-out 0s; 
        }

        @include hover-set {
            &::after {
                opacity: 1;
            }
        }
    }

    &--disabled {
        background-size: 0;
        opacity: 0.5;
    }

    &--active.link-disabled {
        background-size: 0;
    }

    &--active.link-uppercase {
        cursor: default;

        &::after {
            width: 100%;
            opacity: 1;
        }
    }
}