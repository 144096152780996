@import "../../colors/variables";

.lxs-svg-icon {
    display: inline-block;

    &--has-background {
        border-radius: 1000px;
        padding: 5px;
        background: lx-color-transparent("white", "default", 0.25);
    }
}
