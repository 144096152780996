@import "lexus-style-guide/colors/index";

.whats-cvc {
    &__description,
    &__title {
        color: lx-color("deep-blue");
    }

    &__card {
        text-align: center;
    }
}
