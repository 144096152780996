@import "../../../colors/index";
@import "../variables";
@import "../../../utilities/mixins";

.lxs-async-select-field {
    $tick-size: 1.6rem;
    $chevron-size: 1rem;
    position: relative;

    &__input {
        position: relative;
        width: 100%;

        > svg {
            position: absolute;
            top: 50%;
            right: $lxs-form-padding-horizontal;
            transform: translateY(-50%);
            transition-duration: 300ms;
            pointer-events: none;
            opacity: 0;
            transition-property: opacity;
        }
    }

    &__search-icon {
        padding: 0 $lxs-form-padding-horizontal;
    }

    .react-select {
        &__control {
            height: 4.8rem;
            background: none;
            border: none;
            box-shadow: none;
            padding: 0 0 0 $lxs-form-padding-horizontal;
            transition: padding-right 300ms;

            &--menu-is-open {
                .react-select__indicator {
                    transform: rotateZ(180deg);
                }
            }
        }

        &__value-container {
            padding: 0;
        }

        &__input,
        &__input > input,
        &__single-value {
            margin-left: 0;
            font-size: 1.9rem;
            font-weight: 300;
            letter-spacing: 0.15rem;
        }

        &__menu {
            background-color: lx-color("smoke");
        }

        &__option {
            color: lx-color("deep-blue");
            font-size: 1.9rem;
            padding: 1.6rem 2.4rem;

            &--is-focused {
                background-color: lx-color("smoke", "light");
            }

            &--is-selected {
                background-color: lx-color("smoke", "dark");
            }
        }

        &__indicator {
            transform: rotateZ(0deg);
            transition: transform 300ms;
            padding: 0 $lxs-form-padding-horizontal;
        }
    }

    &__blank-default-option {
        display: none;
    }

    .lxs-notch-border-and-label__label {
        max-width: calc(100% - #{$lxs-form-padding-horizontal * 3 + $chevron-size});
    }

    &--valid.lxs-select-field--show-success-tick {
        .lxs-select-field__tick {
            opacity: 1;
        }

        .react-select__control {
            padding-right: $tick-size + $lxs-form-padding-horizontal;
        }
    }

    @include lxs-background-light {
        &__search-icon {
            > svg > path {
                fill: $lxs-form-text-color-light-bg;
            }
        }

        .react-select {
            &__input,
            &__single-value {
                color: $lxs-form-text-color-light-bg;
            }

            &__indicator {
                color: $lxs-form-label-color-light-bg;

                &:hover {
                    color: $lxs-form-label-color-light-bg;
                }
            }
        }
    }

    @include lxs-background-dark {
        &__search-icon {
            > svg > path {
                fill: $lxs-form-text-color-dark-bg;
            }
        }

        .react-select {
            &__input,
            &__single-value {
                color: $lxs-form-text-color-dark-bg;
            }

            &__indicator {
                color: $lxs-form-label-color-dark-bg;

                &:hover {
                    color: $lxs-form-label-color-dark-bg;
                }
            }
        }
    }
}
