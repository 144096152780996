@import "../../../../colors/index";
@import "../../../../typography/index";
@import "../../../../utilities/mixins";
@import "../../../../utilities/variables";
@import "../variables";

.lxs-main-menu-mobile {
    display: block;
    color: lx-color("smoke");
    background-color: $lxs-main-menu-bg-color;

    @include lxs-screen(desktop) {
        display: none;
    }

    &__footer {
        margin-left: $lxs-grid-margin-width-mobile;
        margin-right: $lxs-grid-margin-width-mobile;
        padding-bottom: $lxs-grid-margin-width-mobile;
    }
}
