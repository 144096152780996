@import "../../styles/mixins";

.progress-bar {
    height: 4px;
    background-color: rgba(5, 114, 206, 0.2);
    width: 100%;
    overflow: hidden;

    &__value {
        width: 100%;
        height: 100%;
        background-color: lx-color("inari");
        animation: indeterminate-animation 1s infinite linear;
        transform-origin: 0% 50%;
    }
}
  
@keyframes indeterminate-animation {
    0% {
        transform:  translateX(0) scaleX(0);
    }

    60% {
        transform:  translateX(0) scaleX(0.6);
    }

    100% {
        transform:  translateX(100%) scaleX(0.5);
    }
}