@import "../colors/index";
@import "../grid/mixins";
@import "../utilities/mixins";

%lx-quote,
.lx-quote {
    &.is-bg-deep-blue {
        q {
            color: lx-color("smoke", "light");
        }
    }

    @include lx-gs-bg;

    padding: 100px 0;
    display: flex;

    @include screen(mobile) {
        padding: 50px 10px 50px 10px;
        flex-direction: column;
    }

    q {
        @include lx-font-main;

        display: block;
        font-size: 3.2rem;
        font-weight: 300;
        line-height: 5.6rem;
        quotes: "\201C""\201D""\2018""\2019";
        text-indent: -0.35em;

        @include screen(phone) {
            font-size: 2.2rem;
            line-height: 3.2rem;
            text-indent: -0.3em;
        }

        &::before,
        &::after {
            vertical-align: top;
        }

        &::before {
            content: open-quote;
            margin-right: -0.5rem;
        }

        &::after {
            content: close-quote;
            margin-left: -0.5rem;
        }
    }

    &__author {
        @include lx-h6-style;

        display: block;
        margin-top: 4.6rem;

        @include screen(phone) {
            margin-top: 4rem;
        }

        .is-bg-deep-blue & {
            color: lx-color("white");
        }
    }

    &__orange-line {
        @include lx-orange-line-static (left, 32px, 3px, 16px);
    }

    &.quote-full-width {
        .lx-quote__text {
            width: 100%;

            q {
                text-align: center;
            }
        }

        .lx-quote__orange-line {
            @include lx-orange-line-static (center, 32px, 3px, 16px);
        }

        .lx-quote__author {
            text-align: center;
        }
    }

    &.quote-right {
        flex-direction: row-reverse;

        .lx-quote__text {
            padding-left: 116px;
        }

        @include screen(mobile) {
            flex-direction: column-reverse;

            .lx-quote__text {
                padding-top: 25px;
                padding-left: 0;
            }
        }
    }

    &.quote-left {
        .lx-quote__text {
            padding-right: 116px;

            @include screen(mobile) {
                padding-bottom: 25px;
                padding-right: 0;
            }
        }
    }

    &__text {
        display: flex;
        align-content: center;
        justify-content: center;
        flex-direction: column;
        width: 50%;

        @include screen(mobile) {
            width: 100%;
        }
    }

    &__image {
        width: 50%;

        @include screen(mobile) {
            width: 100%;
        }
    }
}
