@import "../../../../colors/index";
@import "../../../../typography/index";

.lxs-models-menu-mobile-vehicle-card {
    width: 100%;
    height: 100%;
    position: relative;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 62px auto 40px;

    &__name {
        @include lx-orange-line-static(left, 16px, 3px, 4px);
        font-size: lx-font-size("h1", "phone");
        text-transform: uppercase;
        margin-left: $lxs-grid-margin-width-mobile;
        margin-right: $lxs-grid-margin-width-mobile;
        padding-top: $lxs-grid-margin-width-mobile;
        font-weight: 300;
        color: lx-color("deep-blue");

        // senkei line override
        &::after {
            margin-left: 2px;
            font-size: 0;
        }
    }

    &__container {
        padding: 0 4px 12px 4px;
        overflow: hidden;
        align-self: center;

        &-img {
            width: 100%;
        }
    }

    &__footer {
        padding-left: $lxs-grid-margin-width-mobile;
    }

    &__subtitle {
        text-transform: uppercase;
        font-size: 16px;
        letter-spacing: lx-letter-spacing("small");
        display: block;
        margin-right: $lxs-grid-margin-width-mobile;
        overflow: hidden;
        white-space: nowrap;
        font-weight: 400;
        color: lx-color("black", "absolute");
    }

    &__sticker {
        position: absolute;
        right: $lxs-grid-margin-width-mobile;
        top: $lxs-grid-margin-width-mobile;
    }

    &__sticker-tags {
        position: absolute;
        right: $lxs-grid-margin-width-mobile * 3.5;
        top: $lxs-grid-margin-width-mobile;
        display: flex;

        &--no-offer-sticker {
            left: unset;
            right: $lxs-grid-margin-width-mobile;
        }

        > * {
            margin-top: 2px;
            margin-right: 2rem;
        }
    }
}
