@use "sass:math";
@import "../../../../colors/index";
@import "../../../../typography/index";
@import "../variables";

.lxs-main-menu-mobile-nav {
    display: flex;
    padding: math.div($lxs-main-menu-mobile-nav-margin, 2) $lxs-main-menu-mobile-nav-margin;

    &-list {
        margin: 0;
        padding: 0;
        list-style: none;
        position: relative;
        flex: 1;
    }

    &__list-item {
        margin: 0;
        padding: 0;
        text-indent: 0;
        width: 100%;
        overflow-x: hidden;

        &::before {
            display: none;
        }

        > div {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .lxs-svg-icon--has-background {
                padding: 0 4px;
                background-color: $lx-blue-opaque-75;
                height: 24px;
                display: flex;
                align-items: center;

                svg {
                    padding-left: 1px;
                }
            }
        }
    }

    &__item {
        text-decoration: none;
        font-size: lx-font-size("feature");
        padding: 1.5rem 0;
        color: lx-color("smoke");
        cursor: default;
        transition: opacity 500ms;
        display: inline-block;

        &:hover {
            color: lx-color("smoke");
        }

        &--link {
            cursor: pointer;
        }
    }
}
