@use "sass:math";
@import "variables";
@import "mixins";
@import "../colors/index";
@import "../utilities/mixins";
@import "../buttons/mixins";
@import "../typography/index";
@import "../grid/index";

textarea,
input {
    @include remove-appearance;

    border-radius: 0;
}

%is-centered {
    margin-left: auto;
    margin-right: auto;
}

.lx-form-wrapper {
    @extend %lx-gs-row;
    @include lx-gs-row-padding;
}

.lx-form__content {
    padding: $lx-gs-row-padding 0;

    &.is-bg-smoke-light {
        background-color: lx-color("smoke");
    }
}

%lx-form,
.lx-form {
    @include screen(phone) {
        padding: 0 0 $lx-gs-row-padding-mobile;
        width: 100%;
    }

    margin: 0 auto;
    min-width: 300px;
    position: relative;
    width: 80%;
    z-index: 1;

    &.is-aligned-left {
        margin: 0;
    }
}

%lx-form-checkbox,
.lx-form-checkbox {
    float: left;
    opacity: 0;
    position: absolute;
    z-index: -1;
}

%lx-form-checkbox-label,
.lx-form-checkbox-label {
    @include lx-form-checkbox-and-radio-label;

    &::before {
        @include lx-form-checkbox-label-unchecked-box;
    }
}

%lx-form-checkbox-text,
.lx-form-checkbox-text {
    display: inline-block;
    max-width: 90%;
}

%lx-form-checkedbox {
    &::before {
        @include lx-form-checkbox-label-checked-box;
    }
}

@include checked {
    @extend %lx-form-checkedbox;
}

%lx-form-row,
.lx-form-row {
    @include lx-form-row;
}

%lx-form-text-label,
.lx-form-text-label {
    @extend %lx-form-row;
    @include clearfix;

    position: relative;

    @include lx-form-label-text;

    &.is-autocomplete {
        z-index: 2;
    }

    &.is-captcha {
        text-align: left;
    }

    &.is-first-half {
        @include half-label;
    }

    &.is-second-half {
        @include half-label;

        @include screen(wide-screen) {
            float: right;
        }
    }

    &.is-error {
        %lx-form-error,
        .lx-form-error {
            letter-spacing: 0;

            @include lx-font-main;

            color: $lx-c-error;
            display: block;
        }

        %lx-form-element {
            @include lx-form-element-error;
        }
    }
}

%lx-form-element {
    @include lx-form-element;
}

%lx-form-select-icon,
.lx-form-select-icon {
    @extend %icon-arrow-down !optional;

    position: absolute;
    right: 0;
    pointer-events: none;

    &::before {
        color: lx-color("deep-blue");
        font-size: 24px;
        line-height: 45px;
        text-align: center;
        width: 42px;
    }
}

%lx-form-select,
.lx-form-select {
    @extend %lx-form-element;
    @include remove-appearance;

    background: none;
    border-radius: 0;
    font-family: $lx-font-main;
    text-transform: none;
    width: 100%;
    color: lx-color("deep-blue");
}

%lx-form-line-group {
    display: block;
    margin-top: $lx-form-label-input-spacing;
}

.lx-form-select-group {
    @extend %lx-form-line-group;

    display: block;
}

%lx-form-textarea,
.lx-form-textarea {
    @extend %lx-form-element;
    @include lx-form-element-textarea;

    margin: $lx-form-label-input-spacing 0 0;
}

%lx-form-text-input,
.lx-form-text-input {
    @extend %lx-form-element;
    @include lx-form-element-text-input;

    margin: $lx-form-label-input-spacing 0 0;

    &.is-date {
        @include date-arrows {
            padding: 7px 3px;
        }
    }
}

body.ngdialog-open {
    @include screen(mobile) {
        position: fixed;
    }
}

.lx-form-text {
    text-transform: none;
    margin: 0;
}

.lx-form-text-value {
    display: block;
    height: 30px;
    margin: 3px 0 0;
    line-height: 30px;
    font-size: 16px;
    color: lx-color("deep-blue");
    font-family: $lx-font-main;
    font-weight: normal;
    letter-spacing: (math.div(0.2, 16) * 1em);
    text-transform: none;
    width: 100%;

    &__strong {
        @include lx-font-main-bold;

        font-size: 17px;
    }

    &__icon-left {
        margin-right: 20px;
    }

    &__icon-right {
        margin-left: 20px;

        &--percentage {
            margin-left: 8px;
        }
    }
}

.lx-form-text-value-padded-on-top {
    padding-top: 12px;
}

%lx-form-error,
.lx-form-error {
    display: none;
    font-family: $lx-font-main;
    font-size: 12px;
    line-height: 16px;
    margin: 5px 0 0;
    text-transform: none;
}

%lx-form-radio-label,
.lx-form-radio-label {
    @include lx-form-radio-label-style;
}

.lx-form-radio-group {
    @extend %lx-form-line-group;

    font-size: 0;
    position: relative;
    text-align: center;

    &.is-hidden-unselected {
        @include checked("lx-form-checkbox", "lx-form-radio-label") {
            max-height: 50px;
        }

        .lx-form-radio-label {
            margin: 0;
            max-height: 0;
        }

        .lx-form-show-dropdown-icon {
            opacity: 1;
            z-index: 1;
        }
    }
}

@include checked("lx-form-checkbox", "lx-form-radio-label") {
    @include lx-btn-1-style;

    box-shadow: none;
}

%lx-form-radio-group-2,
.lx-form-radio-group-2 {
    @include lx-3-btn-group;

    margin-top: 15px;
}

%lx-form-radio-label-2,
.lx-form-radio-label-2 {
    @include lx-3-btn-item;
    @include lx-btn-5-style;

    margin: 5px;

    &.is-none-transform {
        text-transform: none;
    }
}

@include checked("lx-form-checkbox", "lx-form-radio-label-2") {
    @include lx-btn-1-style;

    box-shadow: none;
}

%lx-form-flex-button-group,
.lx-form-flex-button-group {
    display: flex;
    flex-direction: row;
    margin: $lx-form-label-input-spacing 0 0;
}

%lx-form-flex-checkbox,
.lx-form-flex-checkbox {
    @extend %lx-form-checkbox;

    &:not(:first-child) {
        + .lx-form-flex-radio-label {
            border-left: 0;
        }
    }
}

%lx-form-flex-radio-label,
.lx-form-flex-radio-label {
    @include lx-form-radio-label-style;

    border: 1px solid lx-color("deep-blue");
    box-shadow: none;
    flex-grow: 1;
    margin: 0;
    min-width: initial;
    padding: 0;
    text-transform: none;
}

%lx-form-flex-radio-label-2,
.lx-form-flex-radio-label-2 {
    @include lx-form-radio-label-style;

    border: 1px solid lx-color("deep-blue");
    box-shadow: none;
    flex-grow: 1;
    margin: 0 0 0 10px;
    min-width: initial;
    padding: 0;

    &:first-of-type {
        margin: 0;
    }
}

@include checked("lx-form-flex-checkbox", "lx-form-flex-radio-label") {
    @include lx-btn-1-style;

    border-color: lx-color("deep-blue");
    box-shadow: none;
}

@include checked("lx-form-flex-checkbox", "lx-form-flex-radio-label-2") {
    @include lx-btn-1-style;

    border-color: lx-color("deep-blue");
    box-shadow: none;
}

.lx-form-radio-label-3 {
    @include lx-2-btn-item;
    @include lx-btn-5-style;
    margin: 5px;

    &.is-none-transform {
        text-transform: none;
    }
}

@include checked("lx-form-checkbox", "lx-form-radio-label-3") {
    @include lx-btn-1-style;
    box-shadow: none;
}

%lx-form-submit,
.lx-form-submit {
    @include lx-btn-1;

    @include screen(mobile) {
        margin: 0 auto;
    }

    display: block;
    max-width: $lx-form-submit-max-width;

    &.is-centered {
        @extend %is-centered;
    }
}

%lx-form-submit-item,
.lx-form-submit-item {
    @include lx-btn-1;
    @include lx-3-btn-item;

    @include screen(mobile) {
        margin: 0 auto;
    }

    min-width: 200px;

    &.is-centered {
        @extend %is-centered;
    }
}

.lx-form-captcha {
    display: block;
    width: 304px;
}

.lx-form-hidden-section {
    @include transition(3);

    display: block;
    max-height: 0;
    opacity: 0;
    overflow: hidden;

    &.is-active {
        max-height: 1000px;
        opacity: 1;
    }
}

.lx-form-show-dropdown-icon {
    @extend %icon-edit !optional;

    @include hover-set {
        cursor: pointer;
    }

    @include transition;

    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;

    &::before {
        font-size: 21px;
        line-height: 45px;
        width: 45px;
    }

    color: lx-color("smoke", "light");

    @include hover-set($pseudo-element: "before") {
        color: lx-color("smoke", "light");
    }
}

.lx-form-postcode {
    @extend %lx-form-row;
    @include clearfix;
    @include lx-tg-base;
}
