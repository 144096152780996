@import "../../colors/variables";
@import "../../typography/mixins";
@import "../../css-grid/variables";

.lxs-image-card {
    $lxs-grid-card-margin-width-desktop: $lxs-grid-margin-width-desktop - 10px;

    width: 100%;
    height: 100%;
    position: relative;
    display: grid;
    border-radius: 5px;
    grid-template-columns: 100%;
    grid-template-rows: 96% auto;
    transition: background-color 0.4s ease 0.1s;
    padding-bottom: 32px;
    padding-top: 32px;

    &:hover {
        background-color: lx-color("smoke", "default");

        .lxs-image-card__img {
            opacity: 0;
        }

        .lxs-image-card__img--hover {
            opacity: 1;
        }
    }

    &__top-link {
        text-decoration: none;
        grid-row: 1;
    }

    &__container {
        text-decoration: none;
        padding-top: $lxs-grid-margin-width-mobile;
    }

    &__name {
        @include lx-orange-line-static(left, 16px, 3px, 4px);
        font-size: lx-font-size("h1", "phone");
        text-transform: uppercase;
        margin-left: $lxs-grid-margin-width-mobile;
        margin-right: $lxs-grid-margin-width-mobile;
        font-weight: 300;

        // senkei line override
        &::after {
            margin-left: 2px;
            font-size: 0;
        }

        @include lxs-screen(desktop) {
            font-size: lx-font-size("h1");
            color: lx-color("deep-blue");
            margin-left: $lxs-grid-card-margin-width-desktop;
            margin-right: $lxs-grid-card-margin-width-desktop;
            padding-top: $lxs-grid-card-margin-width-desktop + 2px;

            &::after {
                width: 32px;
            }
        }
    }

    &__img,
    &__img--hover {
        transition: opacity 0.6s;
        grid-column: 1;
        grid-row: 1;
        z-index: 2;
    }

    &__img {
        opacity: 1;
    }

    &__img--hover {
        opacity: 0;
    }

    &__img-container {
        padding: 10px $lxs-grid-margin-width-mobile;
        overflow: hidden;
        align-self: center;
        grid-row: 2;
        display: grid;

        @include lxs-screen(desktop) {
            padding: 0 8px;
        }
    }

    &__subtitle {
        text-decoration: none;
        padding-bottom: 20px;
        display: block;
        padding-left: 30px;

        &-text {
            text-transform: uppercase;
            font-size: lx-font-size("h4");
            letter-spacing: lx-letter-spacing("small");
            display: block;
            line-height: 24px;
            overflow: hidden;
            white-space: nowrap;
            font-weight: 400;
            color: lx-color("black", "absolute");
        }
    }

    &__footer {
        grid-row: 2;
        padding-left: $lxs-grid-margin-width-mobile;

        @include lxs-screen(desktop) {
            padding-left: $lxs-grid-card-margin-width-desktop;
        }

        &-links {
            display: inline-block;

            :hover {
                .lxs-image-card-link__text,
                .lxs-image-card-link__icon > svg > path {
                    transition: opacity 0.7s ease;
                    opacity: 1 !important;
                }
            }

            &:hover {
                .lxs-image-card-link__text,
                .lxs-image-card-link__icon > svg > path {
                    transition: opacity 0.7s ease;
                    opacity: 0.55;
                }
            }
        }
    }

    &__link {
        &-text {
            font-size: 12px;
            font-weight: bold;
            color: lx-color("deep-blue");
            letter-spacing: lx-letter-spacing("small");
            text-transform: uppercase;
        }

        &-icon {
            padding: 0 2px 3px 0;
            position: relative;

            @include lxs-screen(desktop) {
                padding-bottom: 0;
            }
        }
    }

    &__promo-sticker {
        position: absolute;
        right: $lxs-grid-card-margin-width-desktop;
        top: $lxs-grid-card-margin-width-desktop;
        padding-top: 0.5rem;
    }

    &__sticker-tags {
        position: absolute;
        left: 12rem;
        top: $lxs-grid-card-margin-width-desktop;
        display: flex;

        > * {
            margin-top: 0.5rem;
            margin-right: 3rem;
        }
    }
}
