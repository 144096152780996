@import "../../../colors/index";
@import "../../../typography/index";
@import "../../../utilities/mixins";

.lxs-models-menu-vehicles-filter {
    overflow-y: hidden;
    overflow-x: auto;
    white-space: nowrap;

    padding-left: $lxs-grid-margin-width-mobile;

    @include lxs-screen(desktop) {
        padding-left: 0;
    }

    &--fixed {
        @include lxs-screen(desktop) {
            position: fixed;
            top: 0;
            padding-top: 16px;
        }
    }

    & &__button {
        margin-right: 10px;
        border: 0;
        border-radius: 50px;
        cursor: pointer;
        overflow: hidden;
        padding: 1rem 1.6rem;
        text-align: center;
        text-transform: uppercase;
        outline: none;
        font-family: $lx-font-main;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 1.5px;
        color: lx-color("smoke", "default");
        background-color: lx-color("deep-blue", "light");
        transition-property: background-color, color;
        transition-duration: 200ms;

        @include lxs-screen(desktop) {
            background-color: lx-color("smoke", "default");
            color: lx-color("deep-blue", "light");
        }

        &:hover:not(:disabled):not([disabled]) {
            @include lxs-screen(desktop) {
                background-color: lx-color("smoke", "light");
            }
        }

        &--is-active,
        &--is-active:hover:not(:disabled):not([disabled]) {
            background-color: lx-color("smoke", "default");
            color: lx-color("deep-blue", "light");

            @include lxs-screen(desktop) {
                color: lx-color("smoke", "default");
                background-color: lx-color("deep-blue", "light");
            }
        }

        &:disabled,
        &[disabled] {
            opacity: 0.5;
        }
    }
}
