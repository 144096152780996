
@keyframes fadein {
    from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes fadeup {
    0% {
        opacity: 0;
        top: 4px;
    }

    100% {
        opacity: 1;
        top: 0;
    }
}

@keyframes blurin {
    from { filter: blur(10px); }
    to { filter: blur(0.1px); }
}
