@use "sass:math";
@import "../utilities/mixins";

$lx-grid-one-column-portion: math.div(100%, 12);

.lx-gs-bg {
    @include lx-gs-bg;

    &.has-bg-image {
        position: relative;

        > *:not(.lx-gs-bg__image) {
            position: relative;
            z-index: 1;
        }
    }

    &__image {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-position: center;
        background-size: cover;
        z-index: 0;

        &.is-background-repeated {
            background-size: auto;
            background-repeat: repeat;
        }

        &.is-background-fixed {
            background-attachment: fixed;
        }

        &.is-background-transparent {
            opacity: 0.1;
            filter: grayscale(100%);
        }

        &.is-background-anchored-top {
            background-position: top center;
        }

        &.is-background-anchored-center {
            background-position: center;
        }

        &.is-background-anchored-bottom {
            background-position: bottom center;
        }
    }
}

%lx-gs-row,
.lx-gs-row {
    @include clearfix;

    @include screen(tablet) {
        width: 750px;
    }

    @include screen(base) {
        width: 980px;
    }

    @include screen(wide-screen) {
        width: $lxs-grid-max-width-desktop;
    }

    margin: 0 auto;

    &.is-fluid {
        width: 100%;

        %lx-grid-col {
            padding: 0;
        }
    }

    &.is-child-row {
        width: 100%;
    }

    &.is-padded-top {
        padding-top: $lx-gs-row-padding;

        @include screen(phone) {
            padding-top: $lx-gs-row-padding-mobile;
        }

        @include screen(tablet) {
            padding-top: $lx-gs-row-padding-mobile;
        }
    }

    &.is-padded-bottom {
        padding-bottom: $lx-gs-row-padding;

        @include screen(phone) {
            padding-bottom: $lx-gs-row-padding-mobile;
        }

        @include screen(tablet) {
            padding-bottom: $lx-gs-row-padding-mobile;
        }
    }

    &.is-phone-hidden {
        @extend %lx-mu-is-phone-hidden;
    }

    &.is-phone-6 {
        %lx-gs-col-3,
        .lx-gs-col-3 {
            @include screen(phone) {
                width: 50%;
            }
        }
    }
}

%lx-grid-col {
    @include screen(phone) {
        width: 100%;
    }

    float: left;
    overflow: visible;
    padding: 0 $lx-gs-padding;
}

@for $i from 1 through 12 {
    %lx-gs-col-#{$i},
    .lx-gs-col-#{$i} {
        @extend %lx-grid-col;

        @include screen(not-phone) {
            padding: 0 $lx-gs-padding;
            width: $lx-grid-one-column-portion * $i;
        }

        &.is-aligned-center {
            text-align: center;
        }

        &.can-overflow {
            overflow: visible;
        }

        @for $j from 1 through 12 {
            &.is-offset-#{$j} {
                @include screen(phone) {
                    margin-left: 0;
                }

                @include print-styles {
                    margin-left: 0;
                }

                margin-left: $lx-grid-one-column-portion * $j;
            }
        }
    }
}

@for $i from 1 through 12 {
    %lx-gs-mobile-col-#{$i},
    .lx-gs-mobile-col-#{$i} {
        @include screen(phone) {
            padding: 0 $lx-gs-padding;
            width: $lx-grid-one-column-portion * $i;
        }
    }
}

@for $i from 1 through 12 {
    %lx-gs-tablet-col-#{$i},
    .lx-gs-tablet-col-#{$i} {
        @include screen(tablet) {
            padding: 0 $lx-gs-padding;
            width: $lx-grid-one-column-portion * $i;

            @for $j from 0 through 12 {
                &.is-tablet-offset-#{$j} {
                    @include screen(phone) {
                        margin-left: 0;
                    }

                    margin-left: $lx-grid-one-column-portion * $j;
                }
            }
        }
    }
}

@for $i from 1 through 12 {
    %lx-gs-wide-screen-col-#{$i},
    .lx-gs-wide-screen-col-#{$i} {
        @include screen(wide-screen) {
            padding: 0 $lx-gs-padding;
            width: $lx-grid-one-column-portion * $i;

            @for $j from 0 through 12 {
                &.is-wide-screen-offset-#{$j} {
                    @include screen(phone) {
                        margin-left: 0;
                    }

                    margin-left: $lx-grid-one-column-portion * $j;
                }
            }
        }
    }
}

@for $i from 1 through 12 {
    %lx-gs-base-col-#{$i},
    .lx-gs-base-col-#{$i} {
        @include screen(base) {
            padding: 0 $lx-gs-padding;
            width: $lx-grid-one-column-portion * $i;

            @for $j from 0 through 12 {
                &.is-base-offset-#{$j} {
                    @include screen(phone) {
                        margin-left: 0;
                    }

                    margin-left: $lx-grid-one-column-portion * $j;
                }
            }
        }
    }
}

.lx-gs-col-stack-on-tablet {
    @include screen(mobile) {
        width: 100%;

        &[class*=" is-offset-"] {
            margin-left: 0;
        }
    }

    float: right;
}
