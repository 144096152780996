.account__spinner-container {
    height: 100vh;
}

.lxs-registration-form__title--no-senkei, 
.lxs-login-form__header-title--no-senkei,
.lxs-forgot-password__title--no-senkei,
.lxs-otp-verification__title--no-senkei,
.lxs-set-password-form__title--no-senkei,
.lxs-set-password-success__title--no-senkei,
.lxs-account-terms-and-conditions-form__title--no-senkei,
.lxs-email-not-found__title--no-senkei {
    margin-bottom: 3.5rem;
}

.lxs-forgot-password__text,
.lxs-otp-verification__text,
.lxs-email-not-found__text {
    margin-bottom: 25px;
}


.lxs-registration-form__header-text,
.lxs-login-form__header-text,
.lxs-set-password-form__header-text,
.lxs-set-password-success__header-text {
    margin-bottom: 25px;
}
