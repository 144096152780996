@import "../../Form/variables";
@import "../../../utilities/mixins";
@import "../../../forms/mixins";

.lxs-otp-verification {
    &__text-field {
        margin-bottom: 3.2rem;

        span {
            font-size: 1.7rem;
        }
    }

    color: $lxs-form-text-color-dark-bg;

    &__title--no-senkei::after {
        display: none;
    }

    button#{&}__button {
        box-sizing: border-box;
        margin-top: 0.8rem;
        margin-bottom: 3.2rem;
        width: 100%;
    }

    &__link {
        color: $lxs-form-label-color-light-bg;
        display: flex;
        justify-content: center;

        &:hover {
            color: $lxs-form-border-color-light-bg;
        }

        @include lxs-background-dark {
            color: $lxs-form-label-color-dark-bg;

            &:hover {
                color: $lxs-form-border-color-dark-bg;
            }
        }
    }
}
