@import "../../Form/variables";
@import "../../../utilities/mixins";
@import "../../../forms/mixins";

.lxs-checkbox {
    display: flex;

    input[type="checkbox"] {
        border: none;
        width: auto;
    }

    &__input {
        margin: 0;
        cursor: pointer;

        &:focus {
            outline: none;

            &::before {
                outline-style: auto;
                outline-color: $lxs-form-color-light-bg;
            }
        }

        &::before {
            @include lx-form-checkbox-label-unchecked-box;

            @include lxs-background-dark {
                border: 1px solid $lxs-form-border-color-focus-dark-bg;
            }

            flex-direction: row;
        }

        &--checked::before {
            content: "\2713";
            line-height: 1.2;
            text-align: center;
            font-size: 1.4rem;
            transition: box-shadow 0.07s ease-in;

            @include lxs-background-dark {
                color: $lxs-form-text-color-dark-bg;
            }
        }
    }

    &__label {
        @extend %lx-tg-base;

        @include lxs-background-dark {
            color: $lxs-form-text-color-dark-bg;
        }
    }
}
