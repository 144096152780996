@import "../../../../colors/index";
@import "../../../../typography/index";
@import "../../../../lists/index";

.lxs-models-menu-mobile-discover {
    &__title {
        text-align: center;
        font-size: 25px;
        font-weight: lighter;
        margin-bottom: 4rem;
        margin-top: 8rem;
    }

    &__links {
        @include plain-list;
        padding-left: $lxs-grid-margin-width-mobile * 1.5;
        padding-bottom: 3rem;
    }

    &-link {
        &__text {
            @extend %lx-font-main-light;
            @include lx-orange-line-animated(left, 100%, 0, 4px);

            @include hover-set {
                color: lx-color("smoke", "light");
            }

            font-size: 21px;
            display: inline-block;
            color: lx-color("smoke");
            padding: 0;
            letter-spacing: 0;
            text-transform: none;
            text-decoration: none;
            min-height: 5rem;
            line-height: 1.3em;
        }

        &__icon {
            margin-right: 1.5rem;
        }
    }

    &__buttons {
        display: flex;
        flex-direction: column;

        .lxs-button {
            margin-bottom: 2.4rem;
        }
    }
}
