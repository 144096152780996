@import "../utilities/mixins";

.lx-mu-is-phone-hidden,
%lx-mu-is-phone-hidden {
    @include screen(phone) {
        display: none !important;
    }
}

.lx-mu-is-not-phone-hidden,
%lx-mu-is-not-phone-hidden {
    @include screen(not-phone) {
        display: none !important;
    }
}

.lx-mu-is-mobile-hidden,
%lx-mu-is-mobile-hidden {
    @include screen(mobile) {
        display: none !important;
    }
}

.lx-mu-is-desktop-hidden,
%lx-mu-is-desktop-hidden {
    @include screen(desktop) {
        display: none !important;
    }
}
