@use "sass:math";
@import "../../../../colors/index";
@import "../../../../typography/index";

.lxs-models-menu-mobile-body-type {
    display: flex;
    flex-direction: column;
    width: 100vw;

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-left: $lxs-grid-margin-width-mobile;
        margin-right: $lxs-grid-margin-width-mobile;
        margin-bottom: math.div($lxs-grid-margin-width-mobile, 2);
    }

    &__title {
        font-size: 21px;
        font-weight: lighter;
        color: lx-color("deep-blue");
    }

    &__link {
        &-text {
            @extend %lx-font-main-bold;
            @include lx-orange-line-animated(left, 100%, 0, 4px);

            @include hover-set {
                color: lx-color("deep-blue");
            }

            font-size: 12px;
            text-decoration: none;
            text-transform: uppercase;
            letter-spacing: 1.5px;
            display: inline-block;
            padding: 11px 0 5px;
            color: lx-color("deep-blue");
            line-height: 1rem;
        }

        &-icon {
            margin-left: 0.3rem;
        }
    }

    &--hidden {
        display: none;
    }

    &--with-margin {
        margin-bottom: $lxs-grid-margin-width-mobile;

        &:last-child {
            margin-bottom: 0;
        }
    }
}
