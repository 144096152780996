@import "../../../../css-grid/variables";
@import "../../../../utilities/mixins";
@import "../../variables";

.lxs-main-menu-desktop-header {
    position: relative;
    z-index: 2;

    > div {
        height: $lxs-primary-nav-height;

        > div {
            grid-column: 5 / 7;
            justify-self: end;
            align-self: center;

            @include lxs-screen(desktop) {
                grid-column: 9 / 13;
            }
        }
    }
}
