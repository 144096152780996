@import "lexus-style-guide/colors/index";
@import "../../../styles/mixins";

.stripe-card-element {
    &__element {
        padding: 1.125rem 1.6rem 1.125rem 1.6rem;
        border: 0.1rem solid lx-color-transparent("smoke", "light", 0.5);
        border-radius: 0.3rem;
        transition: border 300ms;
    }

    &--focus {
        border: 0.1rem solid lx-color("smoke", "light");
    }

    &--invalid {
        border: 0.1rem solid lx-color("error");
    }
}
