@import "../../../../colors/index";
@import "../../../../typography/index";
@import "../../../../utilities/mixins";
@import "../variables";

.lxs-main-menu-desktop {
    display: none;

    @include lxs-screen(desktop) {
        display: block;
    }
}

.lxs-main-menu-desktop-layout {
    &__left {
        position: fixed;
        left: 0;
        top: 0;
        height: 100vh;
        opacity: 0;
        width: calc(100vw - 400px);
        pointer-events: none;
        overflow: hidden;
        background-position: 50%;
        background-size: cover;
        background-color: lx-color(smoke);

        &--transparent {
            transition: opacity 250ms ease-in-out;
            opacity: 0;
        }

        @include lxs-screen(desktop) {
            width: 25vw;
            opacity: 1;
        }

        @media (min-width: 1192px) {
            width: 32vw;
        }

        @media (min-width: $lxs-grid-max-width-desktop-with-margin) {
            width: 40vw;
        }
    }

    &__right {
        position: absolute;
        min-height: 100%;
        top: 0;
        right: 0;
        background-color: $lxs-main-menu-bg-color;
        color: lx-color("smoke");
        width: 100%;
        padding: 87px 11rem;
        display: flex;
        justify-content: center;

        @include lxs-screen(desktop) {
            width: 75vw;
            justify-content: flex-start;
        }

        @media (min-width: 1192px) {
            width: 68vw;
        }

        @media (min-width: $lxs-grid-max-width-desktop-with-margin) {
            width: 60vw;
        }
    }

    &__image {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-size: cover;
        background-position: center;

        &--front {
            opacity: 0;
            z-index: 2;
            transition: opacity 500ms ease-in-out;
        }

        &--back {
            z-index: 1;
        }
    }
}
