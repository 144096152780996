@use "sass:math";
@import "../typography/mixins";
@import "../utilities/mixins";
@import "../colors/index";
@import "../typography/variables";

@mixin lx-btn-remove-link-underline {
    @include hover-set {
        text-decoration: none;
    }

    text-decoration: none;
}

@mixin lx-btn-vertical-metrics($text-adjust-upwards-px: 0) {
    line-height: 45px - $text-adjust-upwards-px * 2;
    padding-bottom: 0 + $text-adjust-upwards-px * 2;
}

@mixin lx-btn($include-input-rule: true) {
    @include remove-appearance;
    @include lx-btn-remove-link-underline;
    @include font-smoothing;
    @include lx-btn-vertical-metrics($text-adjust-upwards-px: 1);

    border: 0;
    border-radius: 0;
    cursor: pointer;
    display: inline-block;
    font-family: $lx-font-main;
    font-size: 12px;
    font-weight: bold;
    height: 45px;
    letter-spacing: (math.div(2.1, 12) * 1em);
    min-width: 200px;
    overflow: hidden;
    padding-left: 2.4rem;
    padding-right: 2.4rem;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    outline: none;
}

@mixin lx-btn-style($animate, $color, $background-color) {
    @include lx-btn-remove-link-underline;

    &,
    & > * {
        color: $color;
        background-color: $background-color;

        @include hover-set {
            color: $color;
            background-color: $background-color;
        }
    }

    @if $animate {
        background-image: $lx-datauri-inari;
        background-repeat: no-repeat;
        background-position: 50% calc(100% - 10px), 0 0;
        background-size: 1.6rem 2px, 100% 100%;
        transition: background-size 200ms ease-in-out 0s;

        @include hover-set {
            background-size: 3.2rem 2px, 100% 100%;
        }
    }
}

// Deep blue
@mixin lx-btn-1-style($animate: false) {
    @include lx-btn-style($animate, lx-color("smoke"), lx-color("deep-blue"));
}

@mixin lx-btn-1() {
    @include lx-btn;
    @include lx-btn-1-style($animate: true);
}

// Smoke
@mixin lx-btn-2-style($animate: false) {
    @include lx-btn-style($animate, lx-color("deep-blue"), lx-color("smoke"));
}

@mixin lx-btn-2() {
    @include lx-btn;
    @include lx-btn-2-style($animate: true);
}

// Dynamic Button
// Swaps between deep blue and smoke depending on the grid background color
@mixin lx-btn-dynamic() {
    :not(.is-bg-type-dark) & {
        @include lx-btn-1;
    }

    .is-bg-type-dark & {
        @include lx-btn-2;
    }
}

// TODO: Investigate to see if we can remove or consolidate into another button style
// Only used by form-radio-label-2, used to be a light outline.
// So, "Black outline" again.
@mixin lx-btn-5-style($just-color: false, $animate: false) {
    color: lx-color("deep-blue");

    @include hover-set {
        color: lx-color("deep-blue");
    }

    @if not $just-color {
        @include lx-btn-remove-link-underline;

        background-color: transparent;

        @include hover-set {
            background-color: transparent;
        }

        @if $animate {
            background-color: transparent;
            background-image: $lx-datauri-white, $lx-datauri-black;

            @include hover-set {
                background-color: transparent;
            }
        }

        box-shadow: inset 0 0 0 0.1rem lx-color("smoke", "dark");
    }
}

@mixin lx-grouped-btns($numbers) {
    min-width: 70px;

    // If you use 1px IE11 will wrap buttons to the next row prematurely
    width: calc(#{math.percentage(math.div(1, $numbers))} + 0.9999px);
    margin-left: -1px;

    &:first-child {
        margin-left: 0;
        width: math.percentage(math.div(1, $numbers));
    }
}

@mixin lx-grouped-btn-style {
    @include lx-btn-remove-link-underline;
    @include transition;

    background-color: transparent;
    color: lx-color("deep-blue");

    @include hover-set {
        background-color: lx-color-transparent("deep-blue", "default", 0.15);
        color: lx-color("deep-blue");
    }

    box-shadow: inset 0 0 0 1px lx-color("deep-blue");

    @for $i from 1 through 4 {
        &.is-grouped-#{$i} {
            @include lx-grouped-btns($i);

            float: left;

            @if $i == 1 {
                margin-bottom: 10px;
                padding-left: 10px;
                text-align: left;
            }
        }
    }
}

@mixin lx-grouped-btn {
    @include lx-btn;
    @include lx-grouped-btn-style;

    margin: 0;

    &.is-active {
        box-shadow: none;
    }
}

@mixin lx-2-btn-item {
    @include lx-btn;
    @include transition;
    margin: 0 5px;
    min-width: 110px;
    width: calc(50% - 10px);
    position: relative;
    height: 48px;
    line-height: 47px;
    padding-left: 2rem;
    padding-right: 2rem;
}

@mixin lx-3-btn-group {
    @include clearfix;

    display: block;
    margin-left: -5px;
    margin-right: -5px;
    font-size: 0; // children are inline block, kill space between them
    letter-spacing: 0; // REALLY, kill it
    line-height: 0; // ... and ensure their vertical alignment is perfect.
}

@mixin lx-3-btn-item {
    @include lx-btn;
    @include transition;

    margin: 0 5px;
    min-width: 75px;
    width: calc(33.333% - 10px);
    position: relative; // needed to AP multiline-content
}

@mixin lx-4-btn-item {
    @include lx-btn;
    @include transition;

    margin: 0 5px 10px;
    min-width: 75px;
    width: calc(50% - 10px);
    position: relative;
}
