@import "../../../utilities/mixins";

.lxs-social-media {
    &__link {
        display: inline-block;
        margin-right: 24px;
        color: inherit;
        text-decoration: none;
        opacity: 0.5;

        @include lxs-screen(desktop) {
            display: block;
            margin-right: 0;
            margin-bottom: 32px;
            opacity: 1;
            transition: opacity 500ms;
        }

        &:hover {
            color: inherit;
        }

        &:active {
            opacity: 1;
            color: inherit;
        }

        &:last-child {
            margin: 0;
        }

        span#{&}-icon::before {
            font-size: 20px;
            line-height: 20px;
        }
    }
}

.lxs-main-menu-desktop-content--list-item-active {
    .lxs-social-media__link:not(.lxs-social-media__link--active) {
        opacity: 0.25;
        transition: opacity 500ms;
    }
}
