.collapse {
    display: inline-block;

    &__header{
        position: relative;
    }

    &:last-child {
        .collapse__body--open{
            margin-bottom: 220px;
        }
    }
}
