@import "../../../styles/mixins";

.secondary-navigation-links {
    display: none;

    @include desktop {
        display: flex;
        padding-left: 2rem;
    }

    > .secondary-navigation-link {
        margin: 0 2rem;
    }
}

.disabled {
    opacity: 0.5;
    pointer-events: none;
}
