.lxs-email-not-found {
    &__title--no-senkei {
        text-align: left;

        &::after {
            display: none;
        }
    }

    a#{&}__button {
        box-sizing: border-box;
        margin-top: 0.8rem;
        margin-bottom: 3.2rem;
        width: 100%;
    }
}
