@import "../../../colors/index";

.collapse-header {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.7rem 1.6rem;

    &__text {
        margin: 0;
        transition: color 200ms;
        font-weight: 400;
        color: lx-color("smoke", "dark");
        line-height: 1;

        .collapse--open &,
        .collapse__header:hover &,
        .collapse__header:active & {
            color: lx-color("smoke");
        }
    }

    &__icon {
        padding-left: 1.7rem;
    }
}
