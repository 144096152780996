@import "variables";
@import "../../styles/module/index";
@import "lexus-style-guide/colors/index";
@import "lexus-style-guide/utilities/mixins";
@import '../../styles/mixins';
@import '../../styles/global/typography';
@import '../../styles/global/links';
@import "lexus-style-guide/buttons/index";

@mixin content-padding-and-height($padding, $padding-desktop) {
    padding: $padding;

    @include desktop {
        padding: $padding-desktop;
    }
}

.section-header {
    width: 100%;
    z-index: 2;
    position: relative;

    &__content {
        z-index: 2;
        position: relative;
        width: 100%;
        grid-column: 1/7;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        @include desktop {
            grid-column-end: 13;
            flex-direction: row;
            justify-content: space-between;
            margin: 4rem 0;
        }

        @include mobile {
            display: flex;
            flex-direction: row;
            justify-content: left;
            align-items: center;
            padding: 24px 0;
            min-height: unset;
        }
    }

    &-title__text::before {
        display: none;
    }

    &-title__text {
        @include desktop {
            font-weight: 100;
            margin-left: 49.5px;
            font-size: 3rem;
        }
    }

    &__extra-area {
        @include desktop {
            align-self: flex-end;
        }
    }

    &__title-area {
        display: flex;
        align-items: center;
    }

    &--hide-on-mobile {
        display: none;

        @include desktop {
            display: block;
        }
    }

    &--sub-page & {
        &__mobile-sub-back-icon {
            position: unset;
            top: $section-header-chevron-padding-top-sub-page-mobile;
            margin-right: 1.6rem;
        }
    }

    &--flow-page & {
        &__content {
            @include content-padding-and-height(
                $padding: $section-header-padding-flow-page,
                $padding-desktop: $section-header-padding-flow-page-desktop,
            );
        }
    }
}
