@import "../../../../colors/index";
@import "../../../../typography/index";

.lxs-models-menu-desktop-body-type {
    display: flex;
    flex-direction: column;
    margin-bottom: $lxs-grid-margin-width-desktop;

    &--hidden {
        display: none;
    }

    &__header {
        border-bottom: 1px solid lx-color-transparent("white", "default", 0.25);
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-right: 11.1rem;
        margin-bottom: 2.4rem;

        &-title {
            font-size: 22px;
            font-weight: lighter;
            color: lx-color("deep-blue");
        }

        &-link {
            &-text {
                @extend %lx-font-main-bold;
                @include lx-orange-line-animated(left, 100%, 0, 4px);

                @include hover-set {
                    color: lx-color("deep-blue");
                }

                font-size: 12px;
                text-decoration: none;
                text-transform: uppercase;
                letter-spacing: 1.5px;
                padding: 14px 0 5px;
                color: lx-color("deep-blue");
                line-height: 1em;
                display: flex;
                flex-wrap: wrap;
                height: 3.9rem;
            }

            &-icon {
                margin-left: 0.3rem;
                padding: 12px 0 12px;
                margin-top: 1px;
            }
        }
    }

    &__vehicles {
        flex-grow: 2;
    }

    &:last-child {
        margin-bottom: 8.8rem;
    }
}
