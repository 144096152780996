@import "../../../../colors/index";
@import "../../../../typography/index";
@import "../variables";
@import "../../../../utilities/mixins";

.lxs-main-menu-desktop-search {
    $root: &;
    font-size: 21px;
    transition-property: width, background-color;
    transition-duration: 500ms;
    border-radius: 40px;
    padding: 1.4rem 0;
    width: 80%;
    position: relative;

    &--focused {
        background-color: $lx-blue-opaque-75;
    }

    &__button {
        position: absolute;
        top: 0;
        left: 92%;
        padding-top: 19px;
        opacity: 0;
        transition-property: opacity, background-color;
        transition-duration: 300ms;
        background-color: transparent;
        border: none;

        #{$root}--focused > & {
            opacity: 1;
            cursor: pointer;
            transition-delay: 100ms;
        }
    }

    &__icon {
        position: relative;
        display: inline-block;
        padding-right: 1.1rem;
        top: 2px;
        opacity: 1;
        transition-property: opacity;
        transition-duration: 300ms;

        #{$root}--focused > & {
            opacity: 0;
        }
    }

    &__prompt {
        position: absolute;
        font-weight: lighter;
        cursor: pointer;
        padding-top: 3px;
        padding-left: 1px;
        display: inline;

        #{$root}--focused > & {
            display: none;
        }
    }

    &__input {
        font-weight: lighter;
        border: 0;
        background: none;
        font-size: 21px;
        width: 0;
        color: lx-color("smoke");

        #{$root}--focused > & {
            width: 80%;
            outline: none;
        }

        @include ms-clear-remove;

        @include place-holder {
            color: $lxs-main-menu-search-placeholder-color;
        }
    }
}
