@import "../../../../colors/index";
@import "../../../../typography/index";

.lxs-main-menu-desktop-nav {
    margin: 0;
    padding: 0;
    list-style: none;
    position: relative;
    flex: 1;

    &__list-item {
        margin: 0;
        padding: 0;
        text-indent: 0;
        width: 50%;

        &::before {
            display: none;
        }
    }

    &__item {
        display: block;
        text-decoration: none;
        font-size: lx-font-size("h2");
        padding: 2rem 0;
        color: lx-color("smoke");
        cursor: default;
        transition: opacity 500ms;

        &:hover {
            color: lx-color("smoke");
        }

        &--link {
            cursor: pointer;
        }
    }
}
