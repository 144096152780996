@use "sass:math";
@import "../../colors/index";
@import "../../icons/index";
@import "../../utilities/index";
@import "../../typography/variables";
@import "../../typography/mixins";
@import "../shared/variables";

$lxs-herotile-tooltip-size: 16px;
/* stylelint-disable-next-line max-line-length */
$lxs-herotile-tooltip-bottom-spacing: $lxs-content-bottom-spacing + $lxs-content-bottom-margin + $lxs-herotile-tooltip-size;
/* stylelint-disable-next-line max-line-length */
$lxs-herotile-tooltip-bottom-spacing-mobile: $lxs-content-bottom-spacing + $lxs-herotile-tooltip-size + $lxs-tooltip-icon-padding;

.lxs-herotile {
    $inner-margin: 8px;

    @mixin loaded {
        .lxs-herotile--loaded & {
            @content;
        }
    }

    @extend %lxs-lexus-logo-background;
    height: 100vh;
    min-height: 375px;
    max-width: 100%;
    position: relative;
    overflow: hidden;

    &__header {
        font-size: 30px;
        font-weight: 300;
        font-style: normal;
        line-height: 1.1;
        letter-spacing: lx-letter-spacing("large");
        color: lx-color("smoke");
        text-shadow: 0 0 24px lx-color("black", "absolute");
        margin: 0 $inner-margin $lxs-content-bottom-margin $inner-margin;

        @include lxs-screen(desktop) {
            font-size: 42px;
        }

        &--no-senkei::before {
            display: none;
        }

        &-line {
            display: block;
            opacity: 0;

            @include lxs-transition((margin-left, opacity), 2, 4);

            &:nth-child(even) {
                margin-left: -20px;
            }

            &:nth-child(odd) {
                margin-left: 20px;
            }

            &:nth-child(even),
            &:nth-child(odd) {
                @include loaded {
                    margin-left: 0;
                    opacity: 1;
                }
            }

            &--indented {
                padding-left: 20px;

                @include lxs-screen(desktop) {
                    padding-left: 40px;
                }
            }

            &-senkei {
                @include lxs-senkei-line;
            }
        }
    }

    &__header-image {
        opacity: 0;
        margin-bottom: $lxs-content-bottom-margin;

        @include lxs-transition(opacity, 2, 4);

        @include loaded {
            opacity: 1;
            display: block;
            object-fit: contain;
            object-position: bottom;
        }
    }

    &__bottom-section {
        bottom: 0;
        width: 100%;
        position: absolute;
    }

    &__content-wrapper {
        grid-column: 1 / 5;
        position: relative;
        width: 100%;

        @include lxs-screen(desktop) {
            bottom: $lxs-content-bottom-spacing;
        }

        &--has-shading-gradient::before {
            content: " ";
            display: block;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            min-height: 200%;
            pointer-events: none;
            background-image:
                linear-gradient(
                    to top,
                    lx-color-transparent("black", "absolute", 0.9),
                    lx-color-transparent("black", "absolute", 0)
                );

            @include lxs-screen(desktop) {
                bottom: -$lxs-content-bottom-spacing;
            }
        }
    }

    &__content-block {
        position: relative;
        grid-column: 1 / span 6;
        z-index: 1;

        @include lxs-screen(desktop) {
            grid-column-end: span 12;
        }
    }

    &__button {
        $button-min-width: 160px;
        $button-wrapper-width: 510px;
        min-width: $button-min-width;
        flex: 1 1 $button-min-width;
        margin: $inner-margin;

        @include lxs-screen(mobile) {
            max-width: 240px; // reserve space for disclaimer
        }

        &-wrapper {
            display: flex;
            flex-wrap: wrap;
            max-width: $button-wrapper-width;
            white-space: nowrap;
            width: 100%;
            opacity: 0;
            margin-bottom: $lxs-content-bottom-margin;

            @include lxs-transition(opacity, 2, 4, $longer: -0.25s, $later: 0.25s);

            @include loaded {
                opacity: 1;
            }
        }

        &--primary {
            background-color: lx-color-transparent("white", "default", 0.1) !important;
        }

        &--secondary {
            background-color: transparent !important;
            color: lx-color("smoke") !important;
            box-shadow: inset 0 0 0 2px lx-color("deep-blue", "light") !important;
            background-image: radial-gradient(100px at center, #86878d, transparent) !important;
        }

        &-placeholder {
            flex: 1 1 $button-min-width;
            min-width: math.div($button-wrapper-width, 2);
        }
    }

    &__secondary-text {
        max-width: 410px;
        margin: 0 $inner-margin $lxs-content-bottom-margin $inner-margin;
        font-size: 19px;
        font-weight: 300;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.5;
        letter-spacing: 0.3px;
        color: lx-color("smoke");
        text-shadow: 0 0 24px lx-color("black", "absolute");
        opacity: 0;

        @include lxs-transition(opacity, 2, 4);

        @include loaded {
            opacity: 1;
        }

        @include lxs-screen(desktop) {
            font-size: 21px;
        }
    }

    &__tooltip {
        opacity: 0;

        @include lxs-transition(opacity, 2, 4);

        @include loaded {
            opacity: 1;
        }

        &-row {
            padding-left: 70%;
        }

        &-container {
            height: 0;
        }
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        top: calc(100% - #{$lxs-herotile-tooltip-bottom-spacing});
        z-index: 2;
        left: math.div($lxs-tooltip-icon-padding, 2);

        &-paragraph {
            font-size: 15px;
            line-height: 24px;
            color: lx-color("smoke", "light");
            margin: 0;
        }

        @include lxs-screen(mobile) {
            top: calc(100% - #{$lxs-herotile-tooltip-bottom-spacing-mobile});
        }
    }

    &__video {
        background-color: transparent;
        position: absolute;
        width: 100%;
        height: 100%;
        min-height: 100%;
        min-width: 100%;
        max-width: none;
        max-height: none;
        object-fit: cover;
        object-position: 50% 50%;
        pointer-events: none;
    }

    &__image {
        opacity: 0;

        @include lxs-transition(opacity, 1, 3);

        &--loaded {
            opacity: 1;
        }
    }

    .lxs-grid--with-margin {
        margin-left: $lxs-grid-margin-width-mobile - $inner-margin;
        margin-right: $lxs-grid-margin-width-mobile - $inner-margin;

        @include lxs-screen(desktop) {
            margin-left: $lxs-grid-margin-width-desktop - $inner-margin;
            margin-right: $lxs-grid-margin-width-desktop - $inner-margin;
        }
    }
}
