@import "../../typography/index";

@keyframes fade-in {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.lxs-tab, .react-tabs {
    -webkit-tap-highlight-color: transparent;

    &__tab-list {
        padding-bottom: 1rem;
        margin-bottom: 1rem;

        :not(:last-child) {
            border-right: lx-color-transparent("smoke", "default", 0.25) 1px solid;
            padding-right: 1rem;
            margin-right: 0.5rem;
        }

        * {
            font-size: lx-font-size("h3");
        }
    }

    &__tab {
        padding-right: 2rem;
        display: inline-block;

        @include plain-list-item;
        cursor: pointer;

        color: lx-color-transparent("smoke", "default", 0.25);

        &--selected {
            &--theme-dark {
                color: lx-color("smoke");
            }

            &--theme-light {
                color: lx-color("black");
            }
        }

        &--disabled {
            cursor: default;
        }

        &:focus {
            outline: none;

            &::after {
                content: "";
                position: absolute;
                height: 5px;
                left: -4px;
                right: -4px;
                bottom: -5px;
            }
        }
    }

    &__tab-panel {
        display: none;

        &--selected {
            display: block;

            animation-name: fade-in;
            animation-duration:300ms;
            animation-timing-function: linear;
        }
    }
}
