@use "sass:list";
@use "sass:map";
@use "sass:math";
@use "sass:string";
@import "variables";
@import "../utilities/mixins";
@import "../colors/index";

@mixin font-smoothing {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@mixin font-face($name, $filename, $weight: normal, $style: normal, $extensions: woff2 woff) {
    $assets-dir: $lx-font-path;
    $src: null;
    $formats: (
        otf: "opentype",
        ttf: "truetype",
    );

    @each $ext in $extensions {
        $format: if(map.has-key($formats, $ext), map.get($formats, $ext), $ext);
        /* stylelint-disable function-url-quotes */
        $src: list.append(
            $src,
            url(string.quote($assets-dir + $filename + "." + $ext)) format(string.quote($format)),
            comma
        );
        /* stylelint-enable function-url-quotes */
    }

    @font-face {
        font-family: string.quote($name);
        font-style: $style;
        font-weight: $weight;
        src: $src;
    }
}

@mixin lx-font-main {
    @include font-smoothing;

    font-family: $lx-font-main;
    font-weight: 400;
}

@mixin lx-font-main-light {
    @include font-smoothing;

    font-family: $lx-font-main;
    font-weight: 300;
}

@mixin lx-font-main-bold {
    @include font-smoothing;

    font-family: $lx-font-main;
    font-weight: bold;
}

@mixin lx-font-main-italic {
    @include font-smoothing;

    font-family: $lx-font-main;
    font-style: normal;
    font-weight: 400;
}

@mixin lx-h-margin($not-phone-margin, $phone-margin) {
    @include screen(phone) {
        margin-bottom: $phone-margin;
    }

    margin-bottom: $not-phone-margin;
}

@mixin lx-h-font($not-phone-size, $not-phone-line-height, $phone-size, $phone-line-height, $letter-spacing, $color) {
    @include screen(phone) {
        font-size: $phone-size;
        line-height: $phone-line-height;
    }

    color: $color;
    font-size: $not-phone-size;
    letter-spacing: $letter-spacing;
    line-height: $not-phone-line-height;
}

@mixin lx-orange-line-static($align: center, $width: 40px, $height: 4px, $margin-top: 20px) {
    position: relative;

    &::after {
        border-bottom: $height solid lx-color("inari");
        content: " ";
        display: block;
        width: $width;
        margin-top: $margin-top;
        position: absolute;

        @if $align == left {
            left: 0;
            transform: none;
        } @else {
            left: 50%;
            transform: translateX(-50%);
        }
    }
}

@mixin lx-orange-line-animated($animate-from: left, $max-width: 100%, $initial-width: 0%, $margin-top: -2px) {
    &::after {
        content: "";
        display: block;
        border-bottom: 2px solid lx-color("inari");
        height: 0;
        margin-top: $margin-top;
        width: 100%;
        max-width: $initial-width;

        @include transition(max-width);

        @if $animate-from == "center" {
            position: relative;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    &:hover::after {
        max-width: $max-width;
    }
}

@mixin lxs-line-before($width: 24px, $height: 2px, $margin-top: 5px, $margin-bottom: 5px) {
    &::before {
        border-bottom: math.div($height, 2) solid;
        border-top: math.div($height, 2) solid;
        content: " ";
        display: inline-block;
        width: $width;
        margin-bottom: $margin-bottom;
        margin-right: $margin-top;
    }
}

@mixin lxs-colour-line-before($width: 24px, $height: 2px, $margin-top: 5px, $margin-bottom: 5px, $colour: "white") {
    @include lxs-line-before($width, $height, $margin-top, $margin-bottom);

    &::before {
        border-bottom-color: lx-color($colour);
        border-top-color: lx-color($colour);
    }
}

@mixin lxs-colour-line-transition-before(
    $width: 24px,
    $height: 2px,
    $margin-top: 5px,
    $margin-bottom: 5px,
    $type: 3,
    $colour: "white"
) {
    @include lxs-colour-line-before($width, $height, $margin-top, $margin-bottom, $colour);

    &::before {
        @include transition(3);
    }
}

@mixin lx-h1-style {
    @include lx-font-main;
    @include lx-h-font(
        lx-font-size(h1),
        44px,
        lx-font-size(h1, phone),
        32px,
        lx-letter-spacing(large),
        lx-color("deep-blue")
    );
    @include is-author-case;

    text-transform: uppercase;
}

@mixin lx-h1-light-style {
    @include lx-h-font(
        lx-font-size(h1),
        44px,
        lx-font-size(h1, phone),
        35px,
        lx-letter-spacing(large),
        lx-color("deep-blue")
    );
    @include is-author-case;

    text-transform: uppercase;
}

@mixin lx-tg-base-no-line-height {
    @include lx-font-main-light;

    font-size: 19px;
    letter-spacing: 0;
}

@mixin lx-tg-base {
    line-height: 32px;

    @include lx-tg-base-no-line-height;
}

@mixin lx-h2-style {
    @include lx-font-main;
    @include lx-h-font(
        lx-font-size(h2),
        32px,
        lx-font-size(h2, phone),
        32px,
        lx-letter-spacing(medium),
        lx-color("deep-blue")
    );
    @include is-author-case;

    text-transform: uppercase;

    @include screen(phone) {
        text-align: center;
    }
}

@mixin lx-h3-style {
    @include lx-font-main;
    @include lx-h-font(
        lx-font-size(h3),
        32px,
        lx-font-size(h3, phone),
        24px,
        lx-letter-spacing(medium),
        lx-color("deep-blue")
    );
    @include is-author-case;

    text-transform: uppercase;
}

@mixin lx-h4-style {
    @include lx-font-main;
    @include lx-h-font(
        lx-font-size(h4),
        24px,
        lx-font-size(h4, phone),
        24px,
        lx-letter-spacing(small),
        lx-color("deep-blue")
    );
    @include is-author-case;

    text-transform: uppercase;
}

@mixin lx-h5-style {
    @include lx-font-main;
    @include lx-h-font(
        lx-font-size(h5),
        20px,
        lx-font-size(h5, phone),
        20px,
        lx-letter-spacing(small),
        lx-color("deep-blue")
    );
    @include is-author-case;

    text-transform: uppercase;
}

@mixin lx-h5-heavy-style {
    @include lx-font-main-bold;
    @include lx-h-font(
        lx-font-size(h5),
        20px,
        lx-font-size(h5, phone),
        20px,
        lx-letter-spacing(small),
        lx-color("deep-blue")
    );
    @include is-author-case;

    text-transform: uppercase;
}

@mixin lx-h6-style {
    @include lx-font-main;
    @include lx-h-font(
        lx-font-size(h6),
        16px,
        lx-font-size(h6, phone),
        16px,
        lx-letter-spacing(small),
        lx-color("deep-blue")
    );
    @include is-author-case;

    text-transform: uppercase;
}

@mixin lx-h6-heavy-style {
    @include lx-font-main-bold;
    @include lx-h-font(
        lx-font-size(h6),
        16px,
        lx-font-size(h6, phone),
        16px,
        lx-letter-spacing(small),
        lx-color("deep-blue")
    );
    @include is-author-case;

    text-transform: uppercase;
}

@mixin lx-hyperlink-text {
    @include lx-font-main-light;

    @include hover-set {
        color: lx-color("deep-blue");
    }

    color: lx-color("storm");
    text-decoration: underline;
}

@mixin lx-hyperlink-style($color, $secondary-color) {
    color: $color;

    @include hover-set {
        color: $secondary-color;
        text-decoration: none;
    }

    &:active {
        text-decoration: none;
    }

    &:focus {
        color: $secondary-color;
        text-decoration: none;
    }
}

@mixin is-author-case {
    &.is-author-case {
        text-transform: none;
    }
}

@mixin lx-campaign-h1 {
    @include lx-font-main;
    @include is-author-case;

    color: lx-color("smoke", "light");
    text-transform: uppercase;
    font-weight: 300;

    @include screen(phone) {
        line-height: 5.5rem;
        letter-spacing: 0.5rem;
        font-size: 3.2rem;
    }

    @include screen(tablet) {
        line-height: 10rem;
        letter-spacing: 1.5rem;
        font-size: 7.2rem;
    }

    @include screen(desktop) {
        line-height: 17rem;
        letter-spacing: 1.5rem;
        font-size: 11.2rem;
    }
}

@mixin lx-campaign-h3 {
    @include lx-h3-style;

    color: lx-color("smoke", "light");
    font-weight: 300;
    letter-spacing: 0.28rem;
    text-transform: uppercase;

    @include screen(phone) {
        font-size: 1.7rem;
    }
}

@mixin lx-campaign-h4 {
    @extend %lx-h4;

    color: lx-color("smoke", "light");
    font-weight: 300;
    letter-spacing: 0.28rem;
    text-transform: uppercase;
}

@mixin lx-tg-fineprint {
    @extend %lx-font-main-light;

    font-weight: normal;
    color: lx-color("deep-blue", "light");
    font-size: 15px;
    letter-spacing: 0;
    line-height: 24px;

    @include screen(phone) {
        font-size: 15px;
        line-height: 24px;
    }
}

@mixin lx-visually-hidden {
    position: absolute !important;
    clip: rect(1px, 1px, 1px, 1px);
    overflow: hidden;
    height: 1px;
    width: 1px;
    word-wrap: normal;
}

@mixin lx-link($color, $hover-color, $initial-line-width: 16px) {
    @extend %lx-font-main-bold;
    @include lx-orange-line-animated(left, 100%, $initial-line-width, 4px);

    font-size: 12px;
    text-decoration: none;
    text-transform: uppercase;
    line-height: 1.33;
    letter-spacing: 1.5px;
    display: inline-block;
    position: absolute;
    bottom: 30px;
    left: 30px;
    padding: 14px 0 5px;
    color: $color;

    @include hover-set {
        color: $hover-color;
    }
}

@mixin lx-link-1 {
    @include lx-link(lx-color("storm"), lx-color("deep-blue"));
}

@mixin lx-link-2 {
    @include lx-link(lx-color("smoke"), lx-color("smoke", "dark"));
}

@mixin lxs-senkei-line {
    border-bottom: 3px solid lx-color("inari");
    content: " ";
    display: inline-block;
    width: 80px;
    margin-bottom: 12px;
    margin-right: 15px;

    @include screen(mobile) {
        border-bottom: 3px solid lx-color("inari");
        width: 40px;
        margin-bottom: 10px;
        margin-right: 8px;
    }
}
