.lxs-svg-accordion-animated {
    svg {
        transition: transform 500ms;
        transform: rotateZ(0);

        path:first-child {
            transition: transform 500ms;
            transform-origin: center;
            transform: rotateZ(0);
        }
    }

    &.lxs-svg-icon--is-active svg {
        transform: rotateZ(180deg);

        path:first-child {
            transform: rotateZ(-90deg);
        }
    }
}
