.lxs-account-toast {
    &__button {
        display: flex;
        margin: 1.6rem auto;
        box-sizing: border-box !important;
        width: 100%;
        max-width: 360px;
        justify-content: center;

        &:last-child {
            margin-bottom: 0;
        }
    }

    @at-root span#{&} {
        padding-bottom: 0;
    }
}
