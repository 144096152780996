@import "../../../../utilities/mixins";
@import "../../../../colors/index";

.lxs-main-menu-mobile-contact {
    margin: 12px $lxs-grid-margin-width-mobile 32px $lxs-grid-margin-width-mobile;

    &__location-name {
        color: lx-color("smoke");
        font-size: 21px;
        font-weight: 300;
    }

    &__btn-group {
        display: flex;
        margin-top: 24px;
        margin-bottom: 40px;

        .lxs-button {
            flex-grow: 1;
            width: 50%;

            &:nth-child(2) {
                margin-left: 15px;
            }
        }
    }
}
