@import "../../typography/variables";
@import "../../typography/mixins";
@import "../../utilities/mixins";
@import "../shared/variables";

.lxs-scroll-indicator {
    $lxs-scroll-indicator-height: 57px;
    $lxs-scroll-indicator-padding: 8px;
    $lxs-scroll-indicator-content-space: $lxs-content-bottom-spacing + $lxs-content-bottom-margin;
    $lxs-scroll-indicator-size: $lxs-scroll-indicator-height - $lxs-scroll-indicator-padding;
    $lxs-scroll-indicator-bottom-space: $lxs-scroll-indicator-content-space + $lxs-scroll-indicator-size;

    @mixin lxs-scroll-indicator-style {
        display: block;
        position: fixed;
        left: 50%;
        top: calc(100vh - #{$lxs-scroll-indicator-bottom-space});
        transform: translateX(-50%);
        z-index: 3;

        @include lxs-animation(lxs-fade-in, 2, 3, $later: 0.25s);
    }

    display: none;

    @include lxs-screen(desktop) {
        .lxs-modal-open & {
            display: none;
        }

        @media (hover: hover) {
            @include lxs-scroll-indicator-style;
        }

        &__gradient {
            width: 290px;
            height: 129px;
            position: fixed;
            left: 50%;
            opacity: 0;
            transition: opacity 1s ease-out;
            display: block;
            transform: translateX(-50%) translateY(-75%);
            background-image:
                radial-gradient(
                    circle at 50% 50%,
                    lx-color("inari") -23%,
                    lx-color-transparent("inari", "default", 0) 43%
                );
            z-index: -1;
        }

        &__link {
            padding: $lxs-scroll-indicator-padding;
            cursor: pointer;
            display: inline-block;

            @media (hover: hover) {
                &:hover {
                    + .lxs-scroll-indicator__gradient {
                        opacity: 1;
                    }
                }
            }

            &__svg {
                &__circle {
                    animation-name: scroll;
                    animation-duration: 1.5s;
                    animation-timing-function: cubic-bezier(0.65, -0.55, 0.25, 1.5);
                    animation-iteration-count: infinite;
                    transform-origin: 50% 20.5px;
                    will-change: transform, opacity;
                    opacity: 1;
                }

                @keyframes scroll {
                    0%,
                    20% {
                        transform: translateY(0) scaleY(1);
                    }

                    100% {
                        transform: translateY(36px) scaleY(2);
                        opacity: 0;
                    }
                }
            }
        }
    }
}
