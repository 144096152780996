@import "../../../colors/index";

.lxs-main-menu-footer {
    &__link {
        color: lx-color("smoke");
        font-size: 21px;
        text-decoration: none;
        letter-spacing: 0.25px;
        line-height: 32px;
        font-weight: 300;

        &--active {
            opacity: 1;
        }

        &:hover {
            color: lx-color("smoke");
        }
    }

    &__icon {
        &::before {
            padding-right: 13px;
            font-size: 1.6rem !important;
            padding-bottom: 10px;
            position: relative;
            top: -2px;
        }
    }
}

.lxs-main-menu-desktop-content--list-item-active {
    .lxs-main-menu-footer:not(.lxs-main-menu-footer--active) {
        opacity: 0.25;
        transition: opacity 500ms;
    }
}
