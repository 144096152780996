@import "mixins";
@import "../utilities/mixins";
@import "../typography/mixins";

$icon-size: 16px;
$icon-spacing: 16px;
$button-side-padding: 20px;

.lxs-button {
    $block: &;
    $hover-gradient-color: #464a5b;
    $hover-gradient-radius: 100px;
    $hover-gradient-radius--focused: 100%;
    $transition-properties: background-size;

    position: relative;
    display: inline-block;
    padding: 17px $button-side-padding;
    border-radius: 3px;
    border: none;
    font-family: Nobel, Arial, sans-serif;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1.5px;
    line-height: 1.2;
    text-transform: uppercase;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    background-clip: padding-box;
    background-repeat: no-repeat;
    background-size: 0 0;
    background-position: 50% 50%;
    overflow: hidden;
    box-sizing: content-box;
    transition-property: $transition-properties;
    transition-duration: 300ms;

    &:not(#{$block}--shade-dark):not(#{$block}--shade-light):not(#{$block}--shade-dynamic) {
        @include lxs-background-type("light", true) {
            @extend %lxs-button-primary-shade-light;
        }

        @include lxs-background-type("dark", false) {
            @extend %lxs-button-primary-shade-dark;
        }
    }

    // "body.is-page-editor & > a.scEnabledChrome" - is sitecore FIX
    & > #{$block}__content,
    body.is-page-editor & > a.scEnabledChrome {
        pointer-events: none;
        position: relative;
        margin: 0;
        font-size: inherit;
        line-height: inherit;
        color: inherit;
        text-align: inherit;
        letter-spacing: inherit;
        cursor: inherit;
        text-transform: inherit;
        text-decoration: inherit;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    svg {
        margin-right: 8px;
        vertical-align: middle;
    }

    .lxs-radio-button-group > & {
        box-shadow: 0 10px 20px rgba(0, 1, 18, 0.3), 0 6px 6px rgba(0, 1, 18, 0.22);
        transition-property: $transition-properties, box-shadow;

        &:not(:last-child) {
            margin-right: 16px;
        }
    }

    &:hover {
        background-size: #{2 * $hover-gradient-radius} #{2 * $hover-gradient-radius};
    }

    &:focus:not(:hover) {
        outline: none;
        background-size: #{2 * $hover-gradient-radius} #{2 * $hover-gradient-radius};
        background-position: 50% 50% !important;
    }

    &:active {
        background-size: #{4 * $hover-gradient-radius} #{4 * $hover-gradient-radius};
    }

    &--selected,
    .lxs-radio-button-group &--selected {
        cursor: default;
        background-color: $lx-blue-opaque-75;
        box-shadow: none;

        &:hover::before {
            width: 0 !important;
            height: 0 !important;
        }
    }

    &--disabled,
    &--disabled:hover {
        opacity: 0.5;
        cursor: auto;
        background-size: 0 0;
        pointer-events: none;

        &::before {
            display: none;
        }
    }

    @at-root %lxs-button-primary-shade-light {
        background-color: lx-color("deep-blue");
        color: lx-color("smoke");
        background-image: radial-gradient($hover-gradient-radius at center, $hover-gradient-color, transparent);

        &:hover {
            color: lx-color("smoke");
        }

        &:focus {
            background-image: radial-gradient(
                $hover-gradient-radius--focused at center,
                $hover-gradient-color 35%,
                lx-color("deep-blue") 50%
            );
        }

        &#{$block}--selected {
            background-color: lx-color-transparent("deep-blue", "default", 0.87);
        }
    }

    @at-root %lxs-button-primary-shade-dark {
        background-color: lx-color("smoke", "dark");
        color: lx-color("deep-blue");
        background-image: radial-gradient($hover-gradient-radius at center, lx-color("smoke", "light"), transparent);

        &:hover {
            color: lx-color("deep-blue");
        }

        &:focus {
            background-image: radial-gradient(
                $hover-gradient-radius--focused at center,
                lx-color("smoke", "light") 35%,
                lx-color("smoke", "dark") 50%
            );
        }

        &#{$block}--selected {
            background-color: lx-color-transparent("smoke", "light", 0.87);
        }
    }

    @at-root %lxs-button-secondary-shade-light {
        color: lx-color("deep-blue");
        background-color: transparent;
        background-image: radial-gradient(
            $hover-gradient-radius at center,
            lx-color-transparent("white", $opacity: 0.6),
            transparent
        );
        box-shadow: inset 0 0 0 2px lx-color("deep-blue");

        &:hover {
            color: lx-color("deep-blue");
            box-shadow: inset 0 0 0 2px lx-color-transparent("deep-blue", $opacity: 0.1);
        }

        &:focus {
            background-image: radial-gradient(
                $hover-gradient-radius--focused at center,
                lx-color-transparent("smoke", "dark", 0.2) 35%,
                lx-color-transparent("smoke", "dark", 0.5) 50%
            );
            box-shadow: none;
        }

        &#{$block}--selected {
            &,
            &:hover {
                background-color: lx-color-transparent("smoke", "dark", 0.5);
                box-shadow: inset 0 0 0 2px lx-color("deep-blue");
            }
        }

        &#{$block}--disabled {
            &:hover {
                box-shadow: inset 0 0 0 2px lx-color("deep-blue");
            }
        }
    }

    @at-root %lxs-button-secondary-shade-dark {
        background-color: transparent;
        color: lx-color("smoke");
        background-image: radial-gradient($hover-gradient-radius at center, $hover-gradient-color, transparent);
        box-shadow: inset 0 0 0 2px lx-color("smoke");

        &:hover {
            color: lx-color("smoke");
            box-shadow: inset 0 0 0 2px lx-color-transparent("smoke", $opacity: 0.1);
        }

        &:focus {
            background-color: $lx-blue-opaque-75;
            background-image: radial-gradient(
                hover-gradient-radius--focused at center,
                $hover-gradient-color 35%,
                lx-color("deep-blue") 50%
            );
        }

        &#{$block}--selected {
            &,
            &:hover {
                background-color: lx-color("deep-blue", "light");
                box-shadow: none;
            }
        }

        &#{$block}--disabled {
            &:hover {
                box-shadow: inset 0 0 0 2px lx-color("smoke");
            }
        }
    }

    &#{$block}--primary {
        &#{$block}--shade-light {
            @extend %lxs-button-primary-shade-light;
        }

        &#{$block}--shade-dark {
            @extend %lxs-button-primary-shade-dark;
        }

        &,
        &#{$block}--shade-dynamic {
            @include lxs-background-type("light", true) {
                @extend %lxs-button-primary-shade-light;
            }

            @include lxs-background-type("dark", false) {
                @extend %lxs-button-primary-shade-dark;
            }
        }
    }

    &#{$block}--secondary {
        transition-property: $transition-properties, box-shadow;

        &#{$block}--shade-light {
            @extend %lxs-button-secondary-shade-light;
        }

        &#{$block}--shade-dark {
            @extend %lxs-button-secondary-shade-dark;
        }

        &,
        &#{$block}--shade-dynamic {
            @include lxs-background-type("light", true) {
                @extend %lxs-button-secondary-shade-light;
            }

            @include lxs-background-type("dark", false) {
                @extend %lxs-button-secondary-shade-dark;
            }
        }

        &--disabled {
            &:hover {
                background-color: transparent;
            }
        }
    }

    &--icon-left,
    &--icon-right {
        $padding: $icon-spacing + $icon-size + $icon-spacing;
        padding-left: $padding;
        padding-right: $padding;

        #{$block}__icon {
            margin: 0;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    &--icon-left {
        #{$block}__icon {
            left: $icon-spacing;
        }
    }

    &--icon-right {
        #{$block}__icon {
            right: $icon-spacing;
        }
    }

    &--icon-left-center {
        #{$block}__icon {
            margin: 0 $icon-spacing 0 0;
        }
    }

    &--icon-right-center {
        #{$block}__icon {
            margin: 0 0 0 $icon-spacing;
        }
    }
}

// Sitecore Experience Editor Fix
/* stylelint-disable selector-class-pattern */
span.lxs-button {
    & > span,
    body.is-page-editor & > a.scEnabledChrome {
        pointer-events: auto;
        padding: 3px;
    }
}
/* stylelint-enable selector-class-pattern */

.lx-btn-dynamic {
    @include lx-btn-dynamic;
}

// Deep blue
.lx-btn-1 {
    @include lx-btn-1;
}

// Smoke
.lx-btn-2 {
    @include lx-btn-2;
}

%lx-btn-checkable,
.lx-btn-checkable {
    @include lx-btn-1;

    &.is-selected {
        // Don't extend %lx-btn-4 here or .lx-btn-block will need more specificity
        @include lx-btn-1-style;
        @extend %icon-checkmark-after !optional;

        position: relative;

        &::after {
            @include screen(mobile) {
                display: none;
            }

            position: absolute;
            left: 20px;
            font-size: 16px;
            top: 50%;
            transform: translateY(-50%);
            margin-top: -1px;
        }

        &.has-tick-mobile {
            &::after {
                @include screen(mobile) {
                    display: inline-block;
                }
            }
        }
    }
}

%lx-btn-group,
.lx-btn-group {
    font-size: 0;
}

%lx-grouped-btn-1,
.lx-grouped-btn-1 {
    @include lx-grouped-btn;

    &.is-active {
        @include lx-btn-1-style;
    }
}

%lx-grouped-btn-2,
.lx-grouped-btn-2 {
    @include lx-grouped-btn;

    &.is-active {
        @include lx-btn-2-style;
    }
}

@mixin lx-2-btn-item {
    @include lx-btn;
    @include transition;
    margin: 0 5px;
    min-width: 110px;
    width: calc(50% - 10px);
    position: relative;
    height: 48px;
    line-height: 47px;
    padding-left: 2rem;
    padding-right: 2rem;
}

%lx-3-btn-group,
.lx-3-btn-group {
    @include lx-3-btn-group;
}

%lx-3-btn-item,
.lx-3-btn-item {
    @include lx-3-btn-item;
    @include lx-btn-5-style;

    box-shadow: inset 0 0 0 1px lx-color("deep-blue");

    &.is-active {
        @include lx-btn-1-style;
    }
}

%lx-3-btn-item__multiline-content,
.lx-3-btn-item__multiline-content {
    @include is-author-case;

    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    transform: translate(-50%, -50%);
    line-height: 14px;
}

%lx-grouped-btn-4,
.lx-grouped-btn-4 {
    @include lx-4-btn-item;
    @include lx-btn-5-style;

    box-shadow: inset 0 0 0 1px lx-color("deep-blue");

    &.is-active {
        @include lx-btn-1-style;
    }
}

// used for a single icon button that sits next to a form field
%lx-field-icon-btn,
.lx-field-icon-btn {
    @include remove-appearance;
    @include lx-btn-remove-link-underline;
    @include font-smoothing;
    @include transition;

    background-color: lx-color("deep-blue");
    color: lx-color("smoke", "light");

    @include hover-set {
        background-color: lx-color("deep-blue");
        color: lx-color("smoke", "light");
    }

    border: 0;
    border-radius: 0;
    cursor: pointer;
    display: inline-block;
    font-weight: normal;
    height: 45px;
    overflow: hidden;
    text-align: center;
    text-transform: uppercase;
    user-select: none;
    vertical-align: bottom;
    width: 45px;

    &::before {
        line-height: 45px;
        text-align: center;
    }
}

%lx-btn-block,
.lx-btn-block {
    display: block;
    min-width: 0;
}

button.lx-btn-block {
    width: 100%;
}

// Fix <button> elements not filling up to most parents' width

@include screen(phone) {
    .lx-btn-block-for-phone {
        display: block;
        min-width: 0;
    }

    button.lx-btn-block-for-phone {
        width: 100%;
    }
}

@include screen(mobile) {
    .lx-btn-block-for-mobile {
        display: block;
        min-width: 0;
    }

    button.lx-btn-block-for-mobile {
        width: 100%;
    }
}
