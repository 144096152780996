@import "lexus-style-guide/colors/index";

.field-success-tick {
    transition: opacity 300ms;
    position: absolute;
    top: -0.4rem;
    right: 0;
    transform: translateX(50%);
    background-color: lx-color("success");
    width: 2.8rem;
    height: 2.8rem;
    border-radius: 50%;

    svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
