@import "../variables";
@import "../../../utilities/mixins";

.lxs-text-field {
    $tick-size: 1.6rem;

    display: inline-block;
    width: 100%;

    &__input {
        position: relative;
        width: 100%;

        > svg {
            display: flex;
            position: absolute;
            top: 50%;
            right: $lxs-form-padding-horizontal;
            transform: translateY(-50%);
            opacity: 0;
            transition: opacity 300ms;
            pointer-events: none;
        }
    }

    &__clear {
        position: absolute;
        top: 50%;
        right: $lxs-form-padding-horizontal;
        transform: translateY(-50%);
        font-weight: 600;
        font-size: 1.2rem;
        letter-spacing: 0.15rem;
        text-transform: uppercase;
        cursor: pointer;
        transition-property: opacity, right;
        transition-duration: 300ms;
    }

    &__input-element {
        padding:
            #{$lxs-form-padding-vertical + 0.1rem}
            $lxs-form-padding-horizontal
            #{$lxs-form-padding-vertical - 0.1rem}
            $lxs-form-padding-horizontal;
        font-size: 1.9rem;
        font-weight: 300;
        background-color: transparent;
        border: none;
        width: 100%;

        &:focus {
            outline: none;
        }
    }

    &--empty & {
        &__clear {
            opacity: 0;
            pointer-events: none;
        }
    }

    &--valid.lxs-text-field--show-success-tick & {
        &__tick {
            opacity: 1;
        }

        &__search {
            opacity: 0;
        }

        &__clear {
            right: $lxs-form-padding-horizontal + $tick-size + $lxs-form-padding-horizontal;
        }
    }

    .lxs-text-field--show-search & {
        &__search {
            opacity: 1;
        }

        &__clear {
            right: $lxs-form-padding-horizontal + $tick-size + $lxs-form-padding-horizontal;
        }
    }

    &--error & {
        &__border {
            border-color: lx-color("error");
        }

        &__label {
            color: lx-color("error");
        }

        &__search {
            opacity: 0;
        }
    }

    @include lxs-background-light {
        &__clear {
            color: $lxs-form-color-light-bg;
        }

        &__input-element {
            color: $lxs-form-text-color-light-bg;
        }
    }

    @include lxs-background-dark {
        &__clear {
            color: $lxs-form-color-dark-bg;
        }

        &__input-element {
            color: $lxs-form-text-color-dark-bg;
        }
    }
}
