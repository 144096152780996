@import "../../../../colors/index";
@import "../../../../typography/index";
@import "../variables";

.lxs-main-menu-mobile-secondary-screen {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 4;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    color: lx-color("smoke");
    opacity: 0;
    transform: translateX(100%);
    transition: opacity 300ms, transform 300ms ease-out;
    overflow-y: auto;
    overflow-x: hidden;

    &--active {
        opacity: 1;
        transform: translateX(0);
    }

    &-header {
        min-height: 175px;
        width: 100%;
        background-color: lx-color("black");
        display: flex;
        flex-direction: column;
        position: relative;
        background-size: cover;

        &-overlay {
            height: 100%;
            position: absolute;
            width: 100%;
            background-color: lx-color("deep-blue", "dark");
            opacity: 0.65;
        }

        &-close {
            padding-right: $lxs-grid-margin-width-mobile;
            padding-top: $lxs-grid-margin-width-mobile - 4px;
            align-self: flex-end;
            position: relative;

            svg {
                padding-bottom: 2px;
            }
        }

        &-back {
            position: absolute;
            bottom: $lxs-grid-margin-width-mobile;
            padding-left: $lxs-main-menu-mobile-nav-margin * 0.5 - $lxs-main-menu-mobile-nav-back-icon-half-size;

            &--title {
                font-size: lx-font-size("feature", "phone");
                font-weight: 300;
                padding-left: $lxs-main-menu-mobile-nav-margin * 0.5 - $lxs-main-menu-mobile-nav-back-icon-half-size;
            }
        }
    }

    &-body {
        flex-grow: 1;
        width: 100%;
        background-color: lx-color("deep-blue", "dark");
        padding-top: $lxs-grid-margin-width-mobile * 1.2;
    }
}
