@import "../../../styles/mixins";
@import "lexus-style-guide/colors/index";

.header-primary-and-secondary {
    position: relative;

    &::after {
        content: '';
        transition: opacity 300ms;
        opacity: 0;
        position: absolute;
        width: 100%;
        height: 180%;
        z-index: 1;
        top: 0;
        pointer-events: none;

        @include scrim-gradient(lx-color("black"));
    }

    .lxs-grid-row {
        position: relative;
        z-index: 2;
    }

    .lxs-primary-nav {
        position: relative;
        padding-bottom: 0;
        z-index: 2;
        background-image: none;

        &__container {
            border-bottom: lx-color-transparent("white", "default", 0.25) 0.1rem solid;
        }
    }

    .secondary-navigation {
        z-index: 2;
    }
}
