@import "lexus-style-guide/colors/index";

.alert-tile {
    padding: 1.6rem 1.9rem 1.6rem 1.1rem;
    display: flex;
    background-color: lx-color("deep-blue", "light");
    border-radius: 0.3rem;

    &__left {
        margin-right: 0.95rem;
        padding-top: 0.25rem;
    }

    &__right {
        display: flex;
        flex: 1;

        p {
            display: inline;
            font-size: 1.5rem;
            line-height: 2.4rem;
            margin-bottom: 0;
        }
    }

    &--error {
        border-left: solid 4px lx-color("error");
    }

    &--information {
        border-left: solid 4px lx-color("clay");
    }

    &--success {
        border-left: solid 4px lx-color("success");
    }
}
