@import "../../../colors/index";
@import "../../../css-grid/index";
@import "../../../css-grid/variables";
@import "../../../utilities/mixins";
@import "../../Form/variables";

.lxs-account-terms-and-conditions-form {
    display: flex;
    flex-direction: column;
    overflow: hidden;

    &__title--no-senkei {
        text-align: left;

        &::after {
            display: none;
        }
    }

    &__button {
        box-sizing: border-box;
        margin-top: 0.8rem;
        width: calc(100% - 8rem);

        &-container {
            background-color: lx-color("deep-blue", "dark");
            width: calc(100% + 8rem);
            left: -4rem;
            position: absolute;
            bottom: 0;
            display: flex;
            justify-content: center;
            padding: $lxs-grid-gutter-width-desktop $lxs-grid-margin-width-mobile;

            @include lxs-screen(desktop) {
                padding: $lxs-grid-gutter-width-desktop 2 * $lxs-grid-margin-width-desktop;
            }
        }
    }

    &__bottom {
        position: fixed;
        bottom: 0;
        left: 0;
        display: flex;
        align-items: center;
        height: 13rem;
        width: 100%;

        @include lxs-screen(desktop) {
            width: 50%;
        }

        &::before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: lx-color("black");
            opacity: 0.7;
        }

        &--iframe{
            position: relative;
            bottom: unset;
            margin-top: 120px;

            &::before {
                content: none;
            }
        }

        .lxs-button {
            flex: 1;
        }
    }

    &__button-wrapper {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 43rem;
        width: 100%;
        padding: 0 2.4rem;
        display: flex;

        @include lxs-screen(desktop) {
            left: 40%;
        }
    }
}
