@import "../../../typography/index";
@import "../../../utilities/mixins";
@import "./variables";
@import "../common";

.lxs-main-menu-content {
    @extend %lxs-menu-content;

    height: 100%;

    @include lxs-screen(mobile) {
        background-color: $lxs-main-menu-bg-color;
    }
}

.lxs-main-menu-overlay {
    @extend %lxs-menu-overlay;
}

.lxs-modal-open {
    background-color: $lxs-main-menu-bg-color;
}
