@use "sass:math";
@import "../colors/index";
@import "../lists/mixins";
@import "../buttons/mixins";
@import "../utilities/mixins";

%lx-font-main {
    @include lx-font-main;
}

%lx-font-main-light {
    @include lx-font-main-light;
}

%lx-font-main-bold {
    @include lx-font-main-bold;
}

$lx-tg-border-1: 1px solid lx-color("smoke", "dark");
$lx-tg-border-2: 1px solid lx-color("smoke", "dark");

html {
    font-size: 62.5%;
}

body {
    font-family: $lx-font-main;
    font-size: 10px;
}

%lx-h1 {
    @include lx-h1-style;
}

%lx-tg-h1,
h1 {
    @include lx-h1-style;
    @include lx-h-margin(40px, 25px);

    &::before {
        @include lxs-senkei-line;
    }
}

h2 {
    @include lx-h2-style;
    @include lx-h-margin(35px, 25px);
    @include lx-orange-line-static;
}

h3 {
    @include lx-h3-style;
    @include lx-h-margin(28px, 15px);
}

%lx-h4 {
    @include lx-h4-style;
}

%lx-tg-h4,
h4 {
    @include lx-h4-style;
    @include lx-h-margin(18px, 15px);
}

%lx-h5 {
    @include lx-h5-style;
}

%lx-tg-h5,
h5 {
    @include lx-h5-style;

    margin-bottom: 15px;
}

%lx-h6 {
    @include lx-h6-style;
}

%lx-tg-h6,
h6 {
    @include lx-h6-style;

    margin-bottom: 15px;
}

%lx-h5-heavy {
    @include lx-h5-heavy-style;
}

%lx-tg-h5-heavy,
.h5-heavy {
    @include lx-h5-heavy-style;

    margin-bottom: 15px;
}

%lx-h6-heavy {
    @include lx-h6-heavy-style;
}

%lx-tg-h6-heavy,
.h6-heavy {
    @include lx-h6-heavy-style;

    margin-bottom: 15px;
}

%lx-tg-base,
p {
    @include lx-tg-base;

    color: lx-color("deep-blue", "light");
    margin-bottom: 25px;
}

%lx-tg-bold,
b,
strong {
    @include lx-font-main;
}

%lx-tg-italic,
i,
em {
    @include lx-font-main-italic;
    font-style: italic;
}

%lx-tg-a,
a {
    @include lx-hyperlink-text;
}

th,
td {
    @include lx-tg-base;

    color: lx-color("deep-blue");
}

%lx-campaign-h1 {
    @include lx-campaign-h1;

    text-align: center;
}

%lx-campaign-h3 {
    @include lx-campaign-h3;

    text-align: center;
}

%lx-campaign-h4 {
    @include lx-campaign-h4;

    text-align: center;
}

%lx-tg-ul,
ul {
    @include lx-tg-unordered-list;
}

%lx-tg-ol,
ol {
    @include lx-tg-ordered-list;
}

%lx-h5-heavy,
.lx-h5-heavy {
    @include lx-h5-heavy-style;

    margin-bottom: 15px;
}

.lx-h6-heavy {
    @include lx-h6-heavy-style;

    margin-bottom: 15px;
}

%lx-tg-feature,
.lx-tg-feature {
    @extend %lx-font-main-light;

    font-size: 25px;
    letter-spacing: 0.5px;
    line-height: 32px;

    @include screen(phone) {
        font-size: 22px;
    }
}

%lx-tg-reverse,
.lx-tg-reverse {
    @extend %lx-font-main;

    border-color: lx-color("deep-blue");
    color: lx-color("smoke", "light");
    letter-spacing: (math.div(0.1, 14) * 1em);
    padding: 24px 27px;
}

%lx-tg-small,
.lx-tg-small {
    @extend %lx-font-main-light;

    font-size: 17px;
    letter-spacing: (math.div(0.1, 14) * 1em);
    line-height: 24px;

    @include screen(phone) {
        font-size: 17px;
        line-height: 32px;
    }
}

%lx-tg-small-on-mobile,
.lx-tg-small-on-mobile {
    @include screen(mobile) {
        font-size: 17px;
    }
}

%lx-tg-fine-print,
.lx-tg-fine-print {
    @include lx-tg-fineprint;
}

%lx-tg-desktop-text,
.lx-tg-desktop-text {
    @include screen(mobile) {
        display: none;
    }

    display: inline-block;
}

%lx-tg-mobile-text,
.lx-tg-mobile-text {
    @include screen(mobile) {
        display: inline-block;
    }

    display: none;
}

%lx-tg-section-header,
.lx-tg-section-header {
    @include lx-h2-style;

    display: block;
    margin-bottom: 52.5px;
    text-align: center;
    width: 100%;
}

%lx-tg-hr,
.lx-tg-hr {
    border: 0;
    margin: 0;
}

%lx-tg-smaller {
    font-size: 75%;
}

sub,
%lx-tg-subscript {
    @extend %lx-tg-smaller;

    vertical-align: sub;
}

sup,
%lx-tg-superscript {
    @extend %lx-tg-smaller;

    vertical-align: super;
}

.lx-section-divider {
    border: 0;
    border-bottom: 1px solid lx-color("smoke");
    display: block;
    height: 0;
    margin: 0;

    @include screen(phone) {
        padding-top: 0;
    }

    @each $color, $variants in $lx-colors {
        @each $variant, $value in $variants {
            @if $variant != "default" {
                &.is-bg-#{$color}-#{$variant} {
                    border-color: $value;
                }
            } @else {
                &.is-bg-#{$color} {
                    border-color: $value;
                }
            }
        }
    }
}

%lx-tg-section-btn,
.lx-tg-section-btn {
    @include lx-btn;
    @include lx-btn-1-style;

    margin-top: 30px;
}

%lx-tg-is-center-aligned,
.lx-tg-is-center-aligned {
    text-align: center;
}

.lx-link-dynamic {
    @include lx-link-1;

    .is-bg-type-dark .is-bg-type-light &,
    .is-bg-type-light & {
        @include lx-link-1;
    }

    .is-bg-type-light .is-bg-type-dark &,
    .is-bg-type-dark & {
        @include lx-link-2;
    }
}

.lx-link-1 {
    @include lx-link-1;
}

.lx-link-2 {
    @include lx-link-2;
}
