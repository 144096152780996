@import "../../styles/mixins";
@import "lexus-style-guide/colors/index";
@import "lexus-style-guide/css-grid/index";

.pop-tart {
    position: fixed;
    bottom: 2.4rem;
    left: 0;
    right: 0;
    opacity: 0;

    // Ensure this matches "src/constants.ts popTartTransitionTime"
    transition: opacity 300ms;
    z-index: 101;
    width: 100%;
    display: flex;
    justify-content: center;
    padding-left: $lxs-grid-margin-width-mobile;
    padding-right: $lxs-grid-margin-width-mobile;
    height: 0;

    @include desktop {
        padding-left: $lxs-grid-margin-width-desktop;
        padding-right: $lxs-grid-margin-width-desktop;
    }

    &--present {
        height: auto;
    }

    &--show {
        opacity: 1;
    }

    &__content {
        width: 100%;
        max-width: 40rem;
        padding: 4rem 1.4rem;
        background-color: lx-color("smoke");
        box-shadow: 0 1rem 2rem rgba(0, 1, 18, 0.3), 0 0.6rem 0.6rem rgba(0, 1, 18, 0.22);
        border-radius: 0.3rem;
    }

    &__text {
        color: lx-color("deep-blue");
        text-align: center;
        margin: 0;
    }
}
