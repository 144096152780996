@import "../../../../utilities/mixins";
@import "../../../../colors/index";
@import "../../variables";

.lxs-main-menu-mobile-header {
    position: relative;
    z-index: 2;

    > div {
        height: $lxs-primary-nav-height;
    }

    &__social {
        grid-column: 1 / 5;
        align-self: center;
    }

    &__close-button {
        svg {
            margin-bottom: 3px;
            margin-top: 2px;
        }

        grid-column: 5 / 7;
        justify-self: end;
        align-self: center;
        z-index: 3;
        position: relative;
    }
}
