@import "./mixins";
@import "../../colors/index";
@import "../../typography/index";

.lxs-svgicon-link {
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;

    &__icon-left-to-right {
        &--right,
        &--left {
            transition: opacity 0.2s;
            top: 0.7px;
        }

        &--right {
            position: relative;
            right: 10px;
            opacity: 0;
        }

        &--text {
            transition: transform 0.45s !important;
        }

        &:hover {
            @include transition-to-right;
        }
    }
}
