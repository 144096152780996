@use "sass:math";
@import "variables";
@import "../typography/index";
@import "../utilities/mixins";
@import "../colors/index";
@import "../buttons/index";

@mixin first-letter {
    &::first-letter {
        @content;
    }
}

@mixin lx-form-checkbox-label-unchecked-box {
    border: 1px solid lx-color("deep-blue");
    content: " ";
    display: inline-block;
    float: left;
    width: $lx-form-checkbox-radio-size;
    height: $lx-form-checkbox-radio-size;
    margin-right: $lx-form-checkbox-radio-label-spacing;
    transform: translateY(50%);
    margin-top: -1px;
    transition: box-shadow 0.07s ease-out;

    // repaint box-shadow a few times because IE does this weird post-clip blur
    box-shadow:
        0 0 0 0 lx-color("deep-blue") inset,
        0 0 0 0 lx-color("deep-blue") inset,
        0 0 0 0 lx-color("deep-blue") inset,
        0 0 0 0 lx-color("deep-blue") inset,
        0 0 0 0 lx-color("deep-blue") inset;
}

@mixin lx-form-checkbox-label-checked-box {
    color: lx-color("smoke", "light");
    content: "\2713";
    line-height: 1;
    text-align: center;
    font-size: 14px;
    transition: box-shadow 0.07s ease-in;
    box-shadow:
        0 0 0 10px lx-color("deep-blue") inset,
        0 0 0 10px lx-color("deep-blue") inset,
        0 0 0 10px lx-color("deep-blue") inset,
        0 0 0 10px lx-color("deep-blue") inset;
}

@mixin lx-form-radio-label-unchecked-radio {
    border: 1px solid lx-color("deep-blue");
    border-radius: 50%;
    content: " ";
    display: inline-block;
    float: left;
    width: $lx-form-checkbox-radio-size;
    height: $lx-form-checkbox-radio-size;
    margin-right: $lx-form-checkbox-radio-label-spacing;
    transform: translateY(50%);
    margin-top: -1px;
    transition: border-width 0.07s linear;
}

@mixin lx-form-radio-label-checked-radio {
    border-width: 5px;
}

@mixin lx-form-checkbox-and-radio-label {
    @extend %lx-tg-base;

    @include hover-set {
        cursor: pointer;
    }

    display: inline-block;
    margin: 0;
    text-transform: none;
}

@mixin lx-form-row {
    display: block;
    margin-bottom: $lx-form-row-spacing;
    width: 100%;
}

@mixin lx-form-label-text {
    @include lx-h6-heavy-style;

    color: lx-color("deep-blue", "light");
    font-size: 13px;
    letter-spacing: (math.div(1.5, 13) * 1em);
    line-height: $lx-form-label-line-height;
    text-transform: uppercase;
}

@mixin half-label {
    @include screen(wide-screen) {
        display: inline-block;
        width: calc(100% / 2 - 5px);
    }
}

@mixin lx-form-element {
    @include ie-input-fix(45px);

    border: 1px solid lx-color("smoke", "dark");
    font-size: 16px;
    padding: 0 0 0 10px;

    &:focus {
        border-color: lx-color("deep-blue");
        outline: none;
    }
}

@mixin lx-form-element-only-placeholder {
    color: $lx-c-placeholder-grey;
    font-size: 14px;
    font-weight: normal;

    @include screen(mobile) {
        font-size: 16px;
    }
}

@mixin lx-form-element-text-input($placeholder-selector: "pseudo") {
    @if $placeholder-selector == "pseudo" {
        @include place-holder {
            @include lx-form-element-only-placeholder;
        }
    } @else {
        #{$placeholder-selector} {
            @include lx-form-element-only-placeholder;
        }
    }

    color: lx-color("deep-blue");
    background: transparent;
    font-family: $lx-font-main;
    font-weight: normal;
    width: 100%;
}

@mixin lx-form-element-textarea($placeholder-selector: "pseudo") {
    @if $placeholder-selector == "pseudo" {
        @include place-holder {
            @include lx-form-element-only-placeholder;
        }
    } @else {
        #{$placeholder-selector} {
            @include lx-form-element-only-placeholder;
        }
    }

    font-family: $lx-font-main;
    font-size: 16px;
    letter-spacing: 0.5px;
    line-height: 24px;
    max-width: 100%;
    min-height: 140px;
    min-width: 100%;
    padding: 10px;
    width: 100%;
    background: none;
}

@mixin lx-form-element-error {
    border-color: $lx-c-error;

    &:focus {
        border-color: lx-color("deep-blue");
    }
}

@mixin lx-form-radio-label-style {
    @extend %lx-grouped-btn-1;
}
