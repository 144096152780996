@import "../../../colors/index";
@import "../../../forms/variables";

.user-agreement-collapse {
    .collapse {
        margin-bottom: $lx-form-label-input-spacing;

        &__body {
            .svg-accordion path {
                .collapse--open &,
                .collapse__header:hover &,
                .collapse__header:active & {
                    fill: lx-color("smoke", "light");
                }
            }
        }

        &__body-inner {
            padding: 2.4rem 1.7rem 0;
            background-color: lx-color("deep-blue", "dark");
            border-radius: 0 0 0.3rem 0.3rem;
        }

        &__body-text {
            &:last-child {
                margin-bottom: 0;
            }

            &:not(:last-child) {
                margin-bottom: 2rem;
            }
        }
    }

    .collapse-header {
        background-color: lx-color("deep-blue", "dark");
        border-radius: 0.3rem 0.3rem 0 0;
    }
}
