@use "sass:list";
@import "variables";

@mixin clearfix {
    &::before,
    &::after {
        content: " ";
        display: table;
    }

    &::after {
        clear: both;
    }
}

@mixin screen($media) {
    @if $media == (sm-phone) {
        @media screen and (max-width: #{$screen-xs}) {
            @content;
        }
    } @else if $media == phone {
        @media screen and (max-width: #{$screen-sm - 1}) {
            @content;
        }
    } @else if $media == tablet {
        @media screen and (min-width: #{$screen-sm}) and (max-width: #{$screen-md - 1}) {
            @content;
        }
    } @else if $media == base {
        @media screen and (min-width: #{$screen-md}) and (max-width: #{$screen-lg - 1}) {
            @content;
        }
    } @else if $media == wide-screen {
        @media only screen and (min-width: #{$screen-lg}) {
            @content;
        }
    } @else if $media == mobile {
        @media screen and (max-width: #{$screen-md}) {
            @content;
        }
    } @else if $media == desktop {
        @media screen and (min-width: #{$screen-md + 1}) {
            @content;
        }
    } @else if $media == (not-phone) {
        @media screen and (min-width: #{$screen-sm}) {
            @content;
        }
    } @else if $media == (not-wide-screen) {
        @media screen and (max-width: #{$screen-lg - 1}) {
            @content;
        }
    } @else if $media == (short-screen) {
        @media screen and (max-height: #{$screen-sm}) {
            @content;
        }
    } @else if $media == (very-short) {
        @media screen and (max-height: #{$screen-short}) {
            @content;
        }
    } @else if $media == (phone-or-short) {
        @media screen and (max-height: #{$screen-sm}), screen and (max-width: #{$screen-sm - 1}) {
            @content;
        }
    }
}

@mixin print-styles($isRootElement: false) {
    @if $isRootElement {
        &.is-print {
            @content;
        }
    } @else {
        .is-print & {
            @content;
        }
    }

    @media print {
        @content;
    }
}

@mixin hover-set($pseudo-element: false) {
    $selector-suffix: "";

    @if $pseudo-element {
        $selector-suffix: "::" + $pseudo-element;
    }

    &:hover#{$selector-suffix} {
        @content;
    }

    .no-csspointerevents & {
        &:focus#{$selector-suffix},
        &:active#{$selector-suffix} {
            @content;
        }
    }
}

@mixin remove-appearance {
    -webkit-appearance: none;
    -moz-appearance: none;
}

@mixin lxs-animation(
    $name: "lxs-fade-in",
    $startStep: 1,
    $endStep: 2,
    $func: $lxs-default-cubic-bezier,
    $iterations: 1,
    $direction: "normal",
    $fillMode: "both",
    $playState: "running",
    $longer: 0s,
    $later: 0s
) {
    animation: $name /*animation-name*/ lxs-animation-steps-duration($startStep, $endStep) + $longer
        /*animation-duration*/ $func /*animation-timing-function*/ lxs-animation-steps-start($startStep) + $later
        /*animation-delay*/ $iterations /*animation-iteration-count*/ $direction /*animation-direction*/ $fillMode
        /*animation-fill-mode*/ $playState /*animation-play-state*/;
}

@mixin lxs-transition(
    $properties: all,
    $startStep: 1,
    $endStep: 2,
    $func: $lxs-default-cubic-bezier,
    $longer: 0s,
    $later: 0s
) {
    @if type-of($properties) != "list" {
        $properties: list.append((), $properties);
    }

    $rules: ();

    @each $property in $properties {
        $duration: lxs-animation-steps-duration($startStep, $endStep) + $longer;
        $delay: lxs-animation-steps-start($startStep) + $later;
        $rules: list.append($rules, $property $duration $func $delay, comma);
    }
    transition: $rules;
}

@mixin transition($type: 1, $delay: 0s, $attribute: all, $important: 0) {
    $time: 0.2s;

    @if $type == 2 {
        $time: 0.5s;
    }

    @if $type == 3 {
        $time: 0.7s;
    }

    @if $important == 1 {
        transition: #{$attribute} $time ease-in-out $delay !important;
    }

    @if $important == 0 {
        transition: #{$attribute} $time ease-in-out $delay;
    }
}

@mixin ms-clear-remove {
    &::-ms-clear {
        display: none;
    }
}

@mixin ie-input-fix($height) {
    @include ms-clear-remove;

    height: $height;
    line-height: $height;
}

@mixin place-holder {
    &::placeholder {
        @content;
    }
}

@mixin checked($input: lx-form-checkbox, $label: lx-form-checkbox-label) {
    .#{$input} {
        &:checked + .#{$label} {
            @content;
        }
    }
}

@mixin date-arrows {
    &::-webkit-inner-spin-button {
        @content;
    }
}

@mixin dark-mode {
    .is-bg-type-dark {
        @content;
    }
}

@mixin lxs-background-dark() {
    @include lxs-background-type("dark", false) {
        @content;
    }
}

@mixin lxs-background-light() {
    @include lxs-background-type("light", true) {
        @content;
    }
}

@mixin lxs-background-type($type: "light", $primary: false) {
    $main-class: ".is-bg-type-light";
    $secondary-class: ".is-bg-type-dark";

    @if $type == "dark" {
        $main-class: ".is-bg-type-dark";
        $secondary-class: ".is-bg-type-light";
    }

    @if $primary {
        /* Preferably to pass params to @content when this is fixed: https://github.com/sass/node-sass/issues/2685 */
        #{$secondary-class} #{$main-class} &,
        & {
            @content;
        }
    } @else {
        #{$main-class} #{$secondary-class} #{$main-class} &,
        #{$main-class} & {
            @content;
        }
    }
}

@mixin lxs-screen($variance) {
    @if $variance == (desktop) {
        @media all and (min-width: $screen-desktop) {
            @content;
        }
    } @else if $variance == (mobile) {
        @media all and (max-width: ($screen-desktop - 1)) {
            @content;
        }
    } @else if $variance == (small-phone) {
        @media all and (max-width: ($screen-xs - 1)) {
            @content;
        }
    }
}
