@import "../utilities/mixins";
@import "./variables";
@import "./mixins";

.lxs-grid {
    @include lxs-grid;

    &--with-margin {
        margin-left: $lxs-grid-margin-width-mobile;
        margin-right: $lxs-grid-margin-width-mobile;

        @include lxs-screen(desktop) {
            margin-left: $lxs-grid-margin-width-desktop;
            margin-right: $lxs-grid-margin-width-desktop;
        }
    }
}

.lxs-grid-row {
    margin: 0 auto;
    max-width: $lxs-grid-max-width-desktop-with-margin;
}
