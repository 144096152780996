@import "../colors/index";
@import "../utilities/mixins";
@import "../typography/mixins";

@mixin lx-hero-panel-shallow {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(360deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0.0001) 100%);
    opacity: 1;
    transition: opacity 150ms ease-in-out;
}

@mixin lx-gs-row-padding {
    padding: $lx-gs-row-padding 0;

    @include screen(phone) {
        padding: $lx-gs-row-padding-mobile 0;
    }

    @include screen(tablet) {
        padding: $lx-gs-row-padding-mobile 0;
    }
}

@mixin lx-gs-bg($selectors: (p, h1, h2, h3, h4, h5, q, ul, ol)) {
    &.is-bg-smoke {
        background-color: lx-color("smoke");
    }

    &.is-bg-smoke-light {
        background-color: lx-color("smoke", "light");
    }

    &.is-bg-smoke-dark {
        background-color: lx-color("smoke", "dark");
    }

    &.is-bg-deep-blue {
        background-color: lx-color("deep-blue");
    }

    &.is-bg-deep-blue-light {
        background-color: lx-color("deep-blue", "light");
    }

    &.is-bg-deep-blue-dark {
        background-color: lx-color("deep-blue", "dark");
    }

    &.is-bg-type-light {
        @each $selector in $selectors {
            #{$selector} {
                color: lx-color("deep-blue");
            }
        }

        a {
            @include lx-hyperlink-style(lx-color("deep-blue", "dark"), lx-color("deep-blue", "light"));
        }
    }

    &.is-bg-type-dark {
        @each $selector in $selectors {
            #{$selector} {
                color: lx-color("smoke");
            }
        }

        a {
            @include lx-hyperlink-style(lx-color("smoke", "dark"), lx-color("smoke", "light"));
        }
    }
}

@mixin lx-gs-vertical-padding {
    &.is-padded-top {
        padding-top: $lx-gs-row-padding;

        @include screen(phone) {
            padding-top: $lx-gs-row-padding-mobile;
        }

        @include screen(tablet) {
            padding-top: $lx-gs-row-padding-mobile;
        }
    }

    &.is-padded-bottom {
        padding-bottom: $lx-gs-row-padding;

        @include screen(phone) {
            padding-bottom: $lx-gs-row-padding-mobile;
        }

        @include screen(tablet) {
            padding-bottom: $lx-gs-row-padding-mobile;
        }
    }
}
