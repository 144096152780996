@import "lexus-style-guide/colors/index";

.card-payment-method {
    background-color: lx-color("deep-blue", "dark");
    padding: 1.6rem;
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;

    &__card-image {
        margin-right: 1.2rem;
        height: 2rem;
    }

    &__text {
        margin: 0;
        line-height: 2.4rem;
    }

    &__card-last4-digit {
        margin-left: 0.8rem;
        font-weight: 400;
    }

    &__arrow {
        position: absolute;
        right: 5%;
    }
}
