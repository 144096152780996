@import "../../../colors/index";
@import "../../../css-grid/index";
@import "../../../utilities/mixins";
@import "../../Form/variables";

.lxs-registration-form {
    &__title--no-senkei {
        text-align: left;

        &::after {
            display: none;
        }
    }

    &__header-link {
        &:hover {
            color: $lxs-form-border-color-light-bg;
        }

        @include lxs-background-dark {
            color: $lxs-form-label-color-dark-bg;

            &:hover {
                color: $lxs-form-border-color-dark-bg;
            }
        }
    }

    &__name {
        display: flex;
        flex: row;
        gap: 1.6rem;
    }

    &__text-field {
        margin-bottom: 3.2rem;

        span {
            font-size: 1.7rem;
        }
    }

    button#{&}__button {
        box-sizing: border-box;
        margin-top: 0.8rem;
        width: 100%;
    }
}
