@import "variables";
@import "mixins";

@mixin transition-in-1() {
    &__content {
        pointer-events: none;
        opacity: 0;
        transition-property: opacity;
        transition-delay: $desktop-content-delay;
        transition-duration: $content-duration;
    }
}

@mixin transition-in-3() {
    &__image {
        transition-property: width, opacity;
        transition-delay: $mobile-image-delay;
        transition-duration: $image-duration;
        opacity: 1;

        @include layout-desktop {
            transition-delay: $desktop-image-delay;
            width: 100%;
        }
    }

    &__image-overlay {
        @include layout-desktop {
            background-color: rgba($overlay-color, 0.8);
            transition-property: background-color;
            transition-delay: $desktop-overlay-delay;
            transition-duration: $overlay-duration;
        }
    }

    &__image-text-1 {
        transform: translateY(0);
        color: lx-color-transparent("smoke", "default", 1);
        transition-property: color, transform;
        transition-delay: $desktop-text-delay;
        transition-duration: $text-duration;
    }
}

@mixin transition-out-1 {
    &__image {
        width: 100%;
        height: 100%;
        opacity: 1;
    }

    &__image-overlay {
        background-color: rgba($overlay-color, 0.8);
    }

    &__image-text-1 {
        transition-property: color, transform;
        transition-delay: $out-desktop-text-delay;
        transition-duration: $text-duration;
    }
}
