@import "../../styles/module/index";
@import "lexus-style-guide/colors/index";
@import "variables";

.secondary-navigation-menu-mobile {
    position: absolute;
    width: 100%;
    height: 0;
    opacity: 0;
    transition-property: height, opacity;
    transition-duration: $secondary-navigation-menu-mobile-duration;
    background-color: lx-color("deep-blue", "dark");
    z-index: 3;
    overflow: hidden;

    &__navigation {
        padding: 11.8rem 6.4rem 5.6rem 8.1rem;
        display: flex;
        flex-direction: column;
        min-height: 100%;
    }

    &__navigation-primary {
        margin-bottom: 6.4rem;

        .secondary-navigation-menu-mobile__navigation-item {
            &:not(:last-child) {
                margin-bottom: 3.2rem;
            }

            .ownership-link-internal {
                letter-spacing: 0.05rem;
            }
        }
    }

    &__navigation-secondary {
        margin-top: auto;

        .secondary-navigation-menu-mobile__navigation-item {
            &:not(:last-child) {
                margin-bottom: 2.4rem;
            }

            .ownership-link-internal {
                letter-spacing: 0.025rem;
            }
        }
    }

    &__navigation-item {
        display: flex;
        width: 100%;
        text-decoration: none;
        transition-property: transform, opacity;
        transition-duration: $secondary-navigation-menu-mobile-item-duration-out;
        transform: translateY(-15px);
        opacity: 0;

        .ownership-link-internal {
            text-decoration: none;
            font-weight: 300;
        }

        .ownership-link-internal-with-arrow {
            &__arrow {
                margin-left: auto;
            }
        }
    }

    &__navigation-item-icon {
        width: 2.4rem;
        height: 2.4rem;
        margin: auto 0 auto auto;
        display: flex;
        border-radius: 50%;
        background-color: $lx-blue-opaque-75;

        > svg {
            padding-left: 0.1rem;
            margin: auto;
        }
    }

    &--open {
        overflow: auto;
    }

    &--opening,
    &--open {
        transition-property: height, opacity;
        transition-duration: $secondary-navigation-menu-mobile-duration;
        opacity: 1;

        .secondary-navigation-menu-mobile__navigation-item {
            transform: translateY(0);
            opacity: 1;
            transition-duration: $secondary-navigation-menu-mobile-item-duration-in;
        }
    }
}
