@import "../../typography/index";

%lxs-menu-overlay,
.lxs-menu-overlay {
    position: fixed;
    overflow: hidden;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    transition: opacity 500ms ease-in-out;
    z-index: 10000;
    height: 100%;

    body.lxs-modal-after-open & {
        overflow: scroll;
        -webkit-overflow-scrolling: touch;

        @include lxs-screen("desktop") {
            overflow: auto;
        }
    }

    &--after-open {
        opacity: 1;
    }

    &--before-close {
        opacity: 0;
    }
}

%lxs-menu-content,
.lxs-menu-content {
    position: relative;

    &:focus {
        outline: none;
    }
}
