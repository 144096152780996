@import "../../../colors/index";
@import "../../../css-grid/index";
@import "../../../utilities/mixins";
@import "../../Form/variables";

$checkmark-transition-duration: 800ms;

.lxs-account-security-form {
    &__title--no-senkei {
        text-align: left;

        &::after {
            display: none;
        }
    }

    &__text-field {
        margin-bottom: 3.2rem;

        span {
            font-size: 1.7rem;
        }
    }

    button#{&}__button {
        box-sizing: border-box;
        margin-top: 0.8rem;
        width: 100%;
    }
}
