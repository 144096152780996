@import "../../utilities/mixins";
@import "../../typography/variables";
@import "../../typography/mixins";

.lxs-vertical-scroll {
    &__container {
        position: relative;
    }

    &__pager-wrapper {
        float: right;
        padding-right: $lxs-grid-margin-width-mobile;

        @include lxs-screen(desktop) {
            padding-right: $lxs-grid-margin-width-desktop;
        }
    }

    &__pager {
        $lx-timing: 0.5s;
        z-index: 2;
        text-align: center;
        list-style: none;
        opacity: 1;
        display: grid;
        top: 50%;
        transform: translate(-50%, -50%);
        position: fixed;
        cursor: pointer;

        @include lxs-animation(lxs-fade-in, 2, 3, $later: 0.25s);

        &--hide {
            display: none;
        }

        @include lxs-screen(desktop) {
            margin-top: 0;
        }

        &__button {
            padding: 8px 12px;
            text-indent: 0;
            position: relative;
            margin: 0;
            color: currentColor;
            height: 56px;
            width: 3px;
            z-index: -1;

            @include lxs-colour-line-before (3px, 40px, 0, 0, "white");

            &::after {
                content: "";
                position: absolute;
                width: 3px;
            }

            &::before {
                content: "";
                position: absolute;
            }

            &--hide {
                &::after {
                    z-index: 1;
                    border-top: 0;
                    border-bottom: 0;
                    height: 0;
                }
            }

            &--active {
                &::before {
                    transition: all ease-in-out $lx-timing;
                    border-bottom-color: lx-color("inari");
                    border-top-color: lx-color("inari");
                }
            }

            &--not-active {
                &::before {
                    transition: all ease-in-out $lx-timing;
                    border-bottom-color: lx-color("white");
                    border-top-color: lx-color("white");
                }

                &--transition {
                    &::after {
                        z-index: -2;
                        border-top: 40px lx-color("white") solid;
                        transition: all $lx-timing ease-in-out;
                    }
                }

                &--reverse-transition {
                    &::after {
                        border-bottom: 40px lx-color("white") solid;
                        z-index: -2;
                        transition:
                            border-bottom $lx-timing ease-in-out,
                            z-index $lx-timing ease-in-out,
                            border-bottom-color $lx-timing ease-in-out;
                        height: 40px;
                    }
                }
            }
        }
    }
}
