// NOTE: this is a temporary fix.
// Parcel will not handle the url correctly when imported from a scss file.
// This file should not be imported from anywhere else within the styleguide.
// Within a Parcel project, this file should be imported from a javascript file, NOT a scss file.

@import "variables";

@font-face {
    font-family: icomoon;
    src: url("#{$lx-icons-path}icomoon.eot?6p36nr");
    src:
        url("#{$lx-icons-path}icomoon.eot?6p36nr#iefix") format("embedded-opentype"),
        url("#{$lx-icons-path}icomoon.ttf?6p36nr") format("truetype"),
        url("#{$lx-icons-path}icomoon.woff?6p36nr") format("woff"),
        url("#{$lx-icons-path}icomoon.svg?6p36nr#icomoon") format("svg");
    font-weight: normal;
    font-style: normal;
}
