@import "../../styles/mixins";

.pwa-loader {
    padding: 0 6.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    flex-direction: column;
    gap: 50px;

    &__logo {
        width: 100%;
    }

    &__lexus-logo {
        width: 100%;
    }
}