@import "../../../../colors/index";
@import "../../../../typography/index";
@import "../../../../utilities/animations";

.lxs-main-menu-desktop-secondary-nav {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    min-height: 100%;
    list-style: none;
    padding-top: 2rem;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0ms 500ms, opacity 500ms;
    z-index: 1;

    &--active {
        visibility: visible;
        opacity: 1;
        transition: visibility 0s, opacity 500ms;
    }

    &--active & {
        &__list-item {
            animation-name: lxs-fade-in-up;
            animation-fill-mode: both;
            animation-duration: 400ms;
        }
    }

    &__list-item {
        margin: 0;
        padding: 0;
        text-indent: 0;

        &::before {
            display: none;
        }
    }

    &__item {
        display: block;
        color: lx-color("smoke");
        font-weight: 300;
        font-size: lx-font-size("h3");
        letter-spacing: lx-letter-spacing("tiny");
        position: relative;
        text-decoration: none;
        padding: 1rem 0;

        &:hover {
            color: lx-color("smoke");
        }
    }

    &__item-text {
        @include lx-orange-line-animated;
        position: relative;
        display: inline-block;

        &::after {
            margin-top: 0;
        }

        .lxs-main-menu-desktop-secondary-nav__item:hover &::after {
            max-width: 100%;
        }
    }
}
