@import "../../styles/mixins";

.welcome-page {
    width: 100%;

    &__content {
        position: absolute;
        top: 0;
        padding: 9.6rem 2.5rem 11.7rem;
        display: flex;
        flex-direction: column;
        height: 100vh;
        width: 100vw;
        align-items: stretch;

        &__logo {
            align-items: center;
            display: flex;
            flex-direction: column;
            flex-grow: 1;
        }

        &__buttons {
            flex-grow: 1;
            align-content: flex-end;
            display: flex;
            gap: 40px;
            flex-flow: row wrap;
        }
    }

    &__lexus-world {
        h2 {
            margin: .2rem;
            color: #E8E6E0;
        }
    }

    &__title {
        margin: 0;
        padding-left: 4.2rem;
    }

    &__button-group {
        width: 100%;
        display: flex;
        align-content: space-evenly;
        gap:20px;
        flex-flow: row wrap;

        a, button {
            flex-grow: 1;
        }
    }

    h2.senkei-line::after {
        margin-top: 14px;
    }

    &__senkei-line {
        width: 130px;
        height: 4px;
        background-color: lx-color("inari");
        margin-left: auto;
        margin-right: auto;
    }

    &__progress {
        margin-left: auto;
        margin-right: auto;
        width: 130px;
    }
}
