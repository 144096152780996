@import "../../styles/mixins";

.form-grid {
    padding: 4.9rem 0 8.8rem;

    @include desktop {
        padding: 8rem 0;
    }

    &__form-container {
        grid-column: 1 / 7;
        display: flex;
        justify-content: center;

        // This is to prevent the content overflowing the grid columns in firefox
        min-width: 0;

        @include desktop {
            display: block;
            grid-column: 5 / 9;
        }
    }

    &__form {
        @include mobile {
            max-width: 40rem;
            width: 100%;
        }
    }
}
