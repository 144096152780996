@import "../../../../colors/index";
@import "../../../../typography/index";
@import "../../variables";

.lxs-models-menu-mobile {
    @include lxs-screen(desktop) {
        display: none;
    }

    display: flex;
    flex-direction: column;

    &-section-header {
        background: lx-color(deep-blue, dark);
        position: sticky;
        top: -68px;
        z-index: 2;
    }

    &-section-body-types {
        background: lx-color(smoke, dark);
        flex-grow: 2;
        padding-top: 90px;
        margin-top: calc(#{$lxs-grid-margin-width-mobile} - 90px);
        padding-bottom: 88px;
    }

    &-section-discover {
        background: lx-color(deep-blue, dark);
        color: lx-color(smoke);
        padding-left: $lxs-grid-margin-width-mobile;
        padding-right: $lxs-grid-margin-width-mobile;
        padding-bottom: $lxs-grid-margin-width-mobile;
    }
}

.lxs-model-menu-mobile-close-buttons {
    padding-left: 28px;
    height: $lxs-primary-nav-height;
    display: flex;
    align-items: center;

    svg {
        margin-bottom: 3px;
        margin-top: 2px;
    }
}

.lxs-model-menu-mobile-filter {
    padding-bottom: $lxs-grid-gutter-width-mobile;
    padding-top: 5px;

    &__title {
        padding-left: $lxs-grid-margin-width-mobile;
        padding-bottom: 20px;
        color: lx-color(smoke);
        font-size: lx-font-size("h2", "phone");
        font-weight: lighter;
    }
}
