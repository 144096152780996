@import "../../colors/index";
@import "../../css-grid/variables";
@import "../../utilities/index";
@import "./variables";

.lxs-primary-nav {
    &__row {
        position: relative;
    }

    &__container {
        height: $lxs-primary-nav-height;
        position: relative;
        z-index: $lxs-primary-nav-default-z-index;

        @keyframes lxs-primary-nav-load {
            0% {
                width: 0;
                left: 50%;
                opacity: 0;
            }

            100% {
                width: 100%;
                left: 0;
                opacity: 1;
            }
        }

        &::after {
            content: "";
            position: absolute;
            bottom: 0;
            height: 0;
            grid-column: 1 / 7;
            border-bottom: 1px solid lx-color-transparent("white", "default", 0.5);

            @include lxs-animation(lxs-primary-nav-load, 1, 3);

            @include lxs-screen(desktop) {
                grid-column: 1 / 13;
            }
        }

        &-left,
        &-right {
            @include lxs-animation(lxs-fade-in, 2, 3);
        }

        & > div {
            align-self: center;

            &:nth-child(1) {
                grid-column: 1 / 2;
                justify-self: start;

                @include lxs-screen(desktop) {
                    grid-column: 1 / 5;
                }
            }

            &:nth-child(2) {
                grid-column: 2 / 6;
                justify-self: center;

                @include lxs-screen(desktop) {
                    grid-column: 5 / 9;
                }
            }

            &:nth-child(3) {
                grid-column: 6 / 7;
                justify-self: end;

                @include lxs-screen(desktop) {
                    grid-column: 9 / 13;
                    display: flex;
                }
            }
        }

        .lxs-logo__image {
            min-height: 2rem;

            @include lxs-screen(desktop) {
                min-height: 2.9rem;
            }
        }
    }
}

.lxs-primary-nav-contact {
    display: none;

    @include lxs-screen(desktop) {
        color: lx-color("white");
        background-color: lx-color-transparent("black", "absolute", 0.25);
        border-radius: 24px;
        padding: 1.5rem 2rem;
        margin: 0 1.5rem;
        font-size: 13px;
        text-transform: uppercase;
        letter-spacing: 1px;
        cursor: pointer;
        display: flex;

        &__text {
            margin-right: 4px;
            margin-top: 1px;
        }
    }
}
