@import "../../colors/index";
@import "../../utilities/index";

.lxs-toast {
    $mobile-padding: 24px;

    max-width: 400px;
    min-width: 300px;
    border-radius: 3px;
    background-color: lx-color("smoke");
    color: lx-color("deep-blue");
    padding: $mobile-padding;
    line-height: 24px;
    display: flex;
    flex-direction: column;
    margin: auto;

    > * {
        flex-grow: 1;
    }

    &__title {
        color: lx-color("deep-blue");
        font-size: 16px;
        padding-bottom: 14px;
        text-transform: uppercase;
    }

    &__content {
        color: lx-color("deep-blue");
        font-size: 15px;
        padding-bottom: $mobile-padding;
        font-weight: 300;
    }

    &__wrapper {
        position: absolute;
        overflow: hidden;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
        background-color: transparent;

        &:focus {
            outline: none;
        }
    }

    &__overlay {
        z-index: 200; /* Modal value for Main-Site */
        position: fixed;
        overflow: hidden;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        transition: opacity 500ms ease-in-out;
        height: 100%;

        &--after-open {
            opacity: 1;
            background-color: lx-color-transparent("deep-blue", "default", 0.8);
        }

        &--before-close {
            opacity: 0;
        }
    }

    &__close-button {
        position: absolute;
        top: $mobile-padding;
        right: $mobile-padding;

        path {
            fill: lx-color-transparent("deep-blue", "default", 0.5);
        }
    }
}
