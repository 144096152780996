@use "sass:color";
@use "sass:list";
@use "sass:math";
@use "sass:string";
@import "lexus-style-guide/colors/index";
@import "variables";
@import "lexus-style-guide/css-grid/variables";

@mixin font-family() {
    font-family: Nobel, Arial, sans-serif !important;
}

@mixin text() {
    @include font-family;
    font-weight: 300;
    font-size: 1.9rem;
    color: lx-color("smoke");
}

@mixin link() {
    @include text;
    color: lx-color("smoke") !important;
    font-size: 1.7rem;
    font-weight: 500 !important;

    &::first-letter {
        text-transform: uppercase;
    }

    &:hover {
        text-decoration: none;
        color: lx-color("white");
    }
}

@mixin link-uppercase() {
    @include font-family;
    color: lx-color("smoke");
    text-transform: uppercase;
    font-size: 1.2rem;
    font-weight: 600;
    text-decoration: none;
    letter-spacing: 0.15rem;
    transition: $transition-speed-medium;

    &:hover {
        color: lx-color("white");
    }
}

@mixin desktop {
    @media (min-width: $breakpoint) {
        @content;
    }
}

@mixin mobile {
    @media (max-width: ($breakpoint - 1)) {
        @content;
    }
}

@mixin full-height-scrollable {
    height: 100%;
    overflow: auto;
}

/*
    A simple little SCSS mixin for creating scrim gradients
    Inspired by Andreas Larson - https://github.com/larsenwork
    https://css-tricks.com/easing-linear-gradients/

    Copied from https://bluegg.co.uk/writing/making-css-gradients-smooth
*/
@mixin scrim-gradient($startColor: black, $direction: "to bottom") {
    $scrim-coordinates: (
        0: 1,
        19: 0.738,
        34: 0.541,
        47: 0.382,
        56.5: 0.278,
        65: 0.194,
        73: 0.126,
        80.2: 0.075,
        86.1: 0.042,
        91: 0.021,
        95.2: 0.008,
        98.2: 0.002,
        100: 0,
    );
    $hue: color.hue($startColor);
    $saturation: color.saturation($startColor);
    $lightness: color.lightness($startColor);
    $stops: ();

    @each $colorStop, $alphaValue in $scrim-coordinates {
        $stop: hsla($hue, $saturation, $lightness, $alphaValue) math.percentage(math.div($colorStop, 100));
        $stops: list.append($stops, $stop, comma);
    }

    background-image: linear-gradient(string.unquote($direction), $stops);
}

@mixin modal-paddings {
    @include modal-paddings-x;
    @include modal-paddings-y;
}

@mixin modal-paddings-x {
    padding-left: $lxs-grid-margin-width-mobile;
    padding-right: $lxs-grid-margin-width-mobile;

    @include desktop {
        padding-left: $lxs-grid-margin-width-desktop;
        padding-right: $lxs-grid-margin-width-desktop;
    }
}

@mixin modal-paddings-y {
    padding-top: 9.6rem;
    padding-bottom: 4rem;

    @include desktop {
        padding-top: 8rem;
    }
}

@mixin create-stacking-context {
    position: relative;
    z-index: 0;
}

// converts args like the shorthand for padding, where 1, 2, 3, or 4 arguments can be supplied, to a list (top right bottom left)
@function get-shorthand-args($args) {
    @if length($args) == 0 {
        @return (false false false false);
    }

    @if length($args) == 1 {
        $a: list.nth($args, 1);

        @return ($a $a $a $a);
    }

    @if length($args) == 2 {
        $a: list.nth($args, 1);
        $b: list.nth($args, 2);

        @return ($a $b $a $b);
    }

    @if length($args) == 3 {
        $a: list.nth($args, 1);
        $b: list.nth($args, 2);
        $c: list.nth($args, 3);

        @return ($a $b $c $b);
    }

    $a: list.nth($args, 1);
    $b: list.nth($args, 2);
    $c: list.nth($args, 3);
    $d: list.nth($args, 4);

    @return ($a $b $c $d);
}

// accepts shorthand args like regular padding, which are booleans representing if that side should be given padding or not
@mixin content-page-padding($args...) {
    $t-r-b-l: get-shorthand-args($args);
    $top: list.nth($t-r-b-l, 1);
    $right: list.nth($t-r-b-l, 2);
    $bottom: list.nth($t-r-b-l, 3);
    $left: list.nth($t-r-b-l, 4);

    @if $top {
        padding-top: $content-page-padding-top;

        @include desktop {
            padding-top: $content-page-padding-top-desktop;
        }
    }

    @if $right {
        padding-right: $content-page-padding-sides;

        @include desktop {
            padding-right: $content-page-padding-sides-desktop;
        }
    }

    @if $bottom {
        padding-bottom: $content-page-padding-bottom;

        @include desktop {
            padding-bottom: $content-page-padding-bottom-desktop;
        }
    }

    @if $left {
        padding-left: $content-page-padding-sides;

        @include desktop {
            padding-left: $content-page-padding-sides-desktop;
        }
    }
}

@function get-column-width-desktop-calc($cols) {
    @return calc((100% - #{$lxs-grid-gutter-width-desktop * ($cols - 1)}) / #{$cols});
}

@mixin browser-autofill-style($bgColor: lx-color("deep-blue"), $color: lx-color("smoke")) {
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
        -webkit-text-fill-color: $color;
        box-shadow: 0 0 0 1000px $bgColor inset;
        background-color: $bgColor;
        color: $color;
        caret-color: $color;
    }
}

@mixin fill-available {
    width: 100%;
    /* stylelint-disable-next-line value-no-vendor-prefix */
    width: -moz-available;
    /* stylelint-disable-next-line value-no-vendor-prefix */
    width: -webkit-fill-available;
    width: fill-available;
}

@mixin section-header-background {
    background: radial-gradient(89.83% 125.41% at 27.02% 18.51%, #60667E 0%, #60667E 18.23%, rgba(96, 102, 126, 0.00) 99.69%), #313649;
}