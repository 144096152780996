
html,
body,
#root {
    height: 100%;
    overflow: initial;
}

.margin-bottom-zero {
    margin-bottom: 0;
}

.link-disabled {
    text-decoration: none;
    opacity: 0.5;
    cursor: not-allowed;
}
