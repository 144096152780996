@import "variables";
@import "mixins";

$social-media-icon-list: (gplus, facebook, instagram, twitter, youtube, linkedin, linkedin2, pinterest, facebook2);

@include icon-load ("lexus-logo-experience", e908);
@include icon-load ("lexus-logo", e941);
@include icon-load ("lexus-logo-full", e940);
@include icon-load ("steering-wheel", e900);
@include icon-load ("car-front", e901);
@include icon-load ("brochure", e902);
@include icon-load ("gplus", e903);
@include icon-load ("facebook", e904);
@include icon-load ("instagram", e905);
@include icon-load ("twitter", e906);
@include icon-load ("youtube", e907);
@include icon-load ("linkedin", e90b);
@include icon-load ("linkedin2", e90b);
@include icon-load ("pinterest", e90a);
@include icon-load ("phone", e91a);
@include icon-load ("phone2", e91a);
@include icon-load ("drop-pin", e90c);
@include icon-load ("drop-pin2", e90cc);
@include icon-load ("search", e90d);
@include icon-load ("mail", e90e);
@include icon-load ("cross", e913);
@include icon-load ("burger", e914);
@include icon-load ("add", e915);
@include icon-load ("pause", e917);
@include icon-load ("subtract", e916);
@include icon-load ("play", e918);
@include icon-load ("swipe", e925);
@include icon-load ("facebook2", e904);
@include icon-load ("add", e915, after);
@include icon-load ("subtract", e916, after);
@include icon-load ("arrow-up", e911);
@include icon-load ("arrow-down", e912);
@include icon-load ("arrow-left", e90f);
@include icon-load ("arrow-right", e910);
@include icon-load ("skip-back", e90ff);
@include icon-load ("skip-forward", e9101);
@include icon-load ("arrow-up", e911, after);
@include icon-load ("arrow-down", e912, after);
@include icon-load ("arrow-left", e90f, after);
@include icon-load ("arrow-right", e910, after);
@include icon-load ("edit", e91b);
@include icon-load ("download", e91f);
@include icon-load ("question", e91d);
@include icon-load ("info", e919);
@include icon-load ("print", e91c);
@include icon-load ("checkmark", ea10);
@include icon-load ("checkmark", ea10, after);
@include icon-load ("threesixty", e91e);
@include icon-load ("starwhite", e909);
@include icon-load ("starblack", e920);
@include icon-load ("language", e922);
@include icon-load ("locator-pin", e923);
@include icon-load ("warning", e924);
@include icon-load ("burger-light", e93c);
@include icon-load ("ls-design", e93d);
@include icon-load ("ls-performance", e93e);
@include icon-load ("ct-features", e921);
@include icon-load ("ct", e925);
@include icon-load ("rx", e94a);
@include icon-load ("es-design", e926);
@include icon-load ("es-features", e927);
@include icon-load ("es-performance", e928);
@include icon-load ("es-safety", e929);
@include icon-load ("es", e92a);
@include icon-load ("expand", e92b);
@include icon-load ("experiences", e92c);
@include icon-load ("fsport", e92d);
@include icon-load ("hybrid", e92e);
@include icon-load ("is-features", e92f);
@include icon-load ("is", e930);
@include icon-load ("lc-design", e931);
@include icon-load ("lc-enhancements", e932);
@include icon-load ("lc-features", e933);
@include icon-load ("lc-performance", e934);
@include icon-load ("lc", e935);
@include icon-load ("ls-craftsmanship", e936);
@include icon-load ("ls-features", e937);
@include icon-load ("ls", e938);
@include icon-load ("merchandise", e939);
@include icon-load ("origami", e93a);
@include icon-load ("swipe", e93b);
@include icon-load ("cta-facebook", e93f);
@include icon-load ("cta-google-plus", e942);
@include icon-load ("cta-info", e943);
@include icon-load ("cta-instagram", e944);
@include icon-load ("cta-linkedin", e945);
@include icon-load ("cta-pinterest", e946);
@include icon-load ("cta-twitter", e947);
@include icon-load ("cta-youtube", e948);
@include icon-load ("time", e949);
@include icon-load("finance-calculator", e94b);

%lx-icon-section,
.lx-icon-section {
    display: table;
}

%lx-icon-externals,
.lx-icon-externals {
    display: table-cell;
    line-height: 32px;
    vertical-align: top;
}

@include icon-render;

%lxs-lexus-logo-background {
    background-image: $lxs-lexus-background-logo;
    background-repeat: no-repeat;
    background-position: center;
    background-color: lx-color("deep-blue", "dark");
}
