@import "../../../colors/index";
@import "../../../typography/index";
@import "../../../utilities/mixins";
@import "../variables";
@import "../../MainNavContainer/variables";

.lxs-primary-nav-dealer-contact {
    @include lxs-screen(mobile) {
        display: none;
    }

    --lxs-primary-nav-dealer-contact__count: 1;
    padding-top: $lxs-primary-nav-height;
    position: absolute;
    z-index: $lxs-primary-nav-default-z-index;
    overflow: hidden;
    top: 0;
    left: 0;
    height: 0;
    width: 100%;
    transition-duration: 500ms;
    transition-property: opacity, height, min-height;
    transition-timing-function: ease-out;
    opacity: 0;
    background-color: lx-color("deep-blue");
    background-image: linear-gradient(
        to bottom,
        lx-color-transparent($color: $lxs-main-nav-gradient-color, $opacity: 0.278) 56.5%,
        lx-color-transparent($color: $lxs-main-nav-gradient-color, $opacity: 0.126) 73%,
        lx-color-transparent($color: $lxs-main-nav-gradient-color, $opacity: 0)
    );
    color: lx-color("white");

    min-height: 0;

    &--active {
        opacity: 1;
        min-height: calc(#{$lxs-primary-nav-height} + 0.1rem + 6rem * var(--lxs-primary-nav-dealer-contact__count));
        height: calc(100% - #{$lxs-main-nav-padding-bottom});

        .solid-menu & {
            height: 100%;
        }
    }

    &__info-header {
        grid-column: 1 / 5;

        border-right: 1px solid lx-color("white");
        padding-right: 4em;
        min-height: 28px;

        display: flex;
        justify-content: flex-end;
        align-items: center;

        font-size: 12px;
        letter-spacing: 1.5px;
        text-transform: uppercase;
        white-space: nowrap;
    }

    &__info-container {
        grid-column: 5 / 10;

        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        min-height: 28px;
    }

    &__info {
        display: flex;
        align-items: center;

        &:nth-child(2) {
            margin-left: $lxs-grid-margin-width-desktop;
        }
    }

    &__text {
        font-size: 12px;
        letter-spacing: 1.5px;
        text-transform: uppercase;
        margin-right: 8px;
        white-space: nowrap;
    }

    &__link {
        @include hover-set {
            color: lx-color("white");
        }

        font-size: 17px;
        color: lx-color("white");
        white-space: nowrap;
    }

    &__cta {
        grid-column: 10 / 13;
        grid-row: 1 / calc(var(--lxs-primary-nav-dealer-contact__count) + 1);
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100%;

        .lxs-button {
            padding: 12px 48px;
        }
    }

    &__container {
        opacity: 0;
        transition: opacity 500ms ease-out 250ms;
        height: 100%;
        grid-auto-rows: 6rem;
        align-items: center;

        &--active {
            opacity: 1;
        }
    }

    &__wrapper {
        height: 100%;
    }
}
