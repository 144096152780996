@import "../../../colors/index";
@import "../variables";
@import "../../../utilities/mixins";

.lxs-field-below {
    padding: 0 1.6rem;
    margin-top: 1.2rem;
    display: flex;
    justify-content: space-between;
    min-height: 15px;

    &__message,
    &__letter-count {
        font-size: 1.5rem;
        font-weight: 300;
        transition: color 300ms;
    }

    &__message {
        margin-right: 0.8rem;
    }

    &__letter-count {
        white-space: nowrap;
    }

    @include lxs-background-light {
        &__message,
        &__letter-count {
            color: rgba($lxs-form-text-color-light-bg, 0);

            &--show {
                color: rgba($lxs-form-text-color-light-bg, 1);
            }
        }
    }

    @include lxs-background-dark {
        &__message,
        &__letter-count {
            color: rgba($lxs-form-text-color-dark-bg, 0);

            &--show {
                color: rgba($lxs-form-text-color-dark-bg, 1);
            }
        }
    }
}
