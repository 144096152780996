@import "lexus-style-guide/colors/index";

.success-tick {
    display: inline-block;
    pointer-events: none;
    text-align: center;

    * {
        animation-duration: inherit;
    }

    svg {
        width: 10rem;
        height: 10rem;
    }

    .circle {
        stroke: inherit !important;
        opacity: 0;
        stroke-dasharray: 130;
        stroke-dashoffset: 130;
        stroke-width: 1 !important;
        transition-property: all;
        transition-delay: 0ms;
        transition-duration: 500ms;
        animation-name: success-tick-transition-circle;
    }

    .tick {
        stroke: inherit !important;
        stroke-dasharray: 50;
        stroke-dashoffset: 50;
        stroke-width: 1 !important;
        transition: all 500ms ease-out 0ms;
        animation-name: success-tick-transition-tick;
    }

    &__text {
        color: inherit;
        pointer-events: none;
        opacity: 0;
        margin-top: 2.3rem;
        font-weight: 300;
        transition-property: opacity;
        transition-delay: 0ms;
        transition-duration: 500ms;
        animation-name: success-tick-transition-text;

        &::after {
            display: none;
        }
    }

    &--animate-in {
        .tick {
            opacity: 1;
            stroke-dashoffset: 0;
            animation-name: success-tick-transition-in-tick;
        }

        .circle {
            opacity: 1;
            stroke-dashoffset: 0;
            animation-name: success-tick-transition-in-circle;
        }

        .success-tick__text {
            opacity: 1;
            animation-name: success-tick-transition-in-text;
        }
    }

    &--dont-animate {
        .tick {
            opacity: 1;
            stroke-dashoffset: 0;
            animation: none;
        }

        .circle {
            opacity: 1;
            stroke-dashoffset: 0;
            animation: none;
        }

        .success-tick__text {
            opacity: 1;
            animation: none;
        }
    }
}

@keyframes success-tick-transition-in-tick {
    0% {
        opacity: 0;
        stroke-dashoffset: 50;
    }

    50% {
        opacity: 0;
        stroke-dashoffset: 50;
    }

    100% {
        opacity: 1;
        stroke-dashoffset: 0;
    }
}

@keyframes success-tick-transition-in-circle {
    0% {
        opacity: 0;
        stroke-dashoffset: 130;
    }

    100% {
        opacity: 1;
        stroke-dashoffset: 0;
    }
}

@keyframes success-tick-transition-in-text {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes success-tick-transition-tick {
    0% {
        opacity: 0;
        stroke-dashoffset: 50;
    }

    16.666% {
        opacity: 0;
        stroke-dashoffset: 50;
    }

    50% {
        opacity: 1;
        stroke-dashoffset: 0;
    }

    76.766% {
        opacity: 1;
        stroke-dashoffset: 0;
    }

    100% {
        opacity: 0;
        stroke-dashoffset: 0;
    }
}

@keyframes success-tick-transition-circle {
    0% {
        opacity: 0;
        stroke-dashoffset: 130;
    }

    33.333% {
        opacity: 1;
        stroke-dashoffset: 0;
    }

    76.766% {
        opacity: 1;
        stroke-dashoffset: 0;
    }

    100% {
        opacity: 0;
        stroke-dashoffset: 0;
    }
}

@keyframes success-tick-transition-text {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    83.333% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}
