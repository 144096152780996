.lxs-svg-bento-animated {
    $time: 400ms;
    enable-background: new 0 0 128 128;

    #el_LtB6nwKklW,
    #el_hlw58gKg3Y,
    #el_EryYtdQXnn,
    #el_nDfD72sddD,
    #el_G0nNJcCLjC,
    #el_EBwCd5k4r_ {
        fill-rule: evenodd;
        clip-rule: evenodd;
    }

    #el_QpqSs2t2m-_an_JDuNXaYrm,
    #el_CX_j7ruqR9_an_eTNCsuTT7 {
        transform: translate(64px, 64px) translate(-64px, -64px) translate(0, 0);
    }

    #el_QpqSs2t2m-_an_i6aW4JhyU,
    #el_CX_j7ruqR9_an_R1zZn3Roa,
    #el_EBwCd5k4r__an_Md2ws28Zz,
    #el_G0nNJcCLjC_an_kmayPM912,
    #el_nDfD72sddD_an_DdcEnEHsU,
    #el_EryYtdQXnn_an_DiKJea5DL,
    #el_95EcvrmdFju_an_7lPbZKoNz,
    #el_djo-q9OiNNB_an_-Rlc-BjL6,
    #el_nZhptVjLUN_an_osEx3nf1f,
    #el_JAJ0s_BWb8_an_AhKs_GDz1,
    #el_QpqSs2t2m- {
        transition-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
        transition-duration: $time;
    }

    #el_QpqSs2t2m-_an_i6aW4JhyU,
    #el_CX_j7ruqR9_an_R1zZn3Roa {
        transform: translate(64px, 64px) rotate(0deg) translate(-64px, -64px);

        .lxs-svg-icon--is-active & {
            transform: translate(64px, 64px) rotate(-45deg) translate(-64px, -64px);
        }
    }

    #el_EBwCd5k4r__an_Md2ws28Zz,
    #el_95EcvrmdFju_an_7lPbZKoNz {
        transform: translate(60px, 60px) translate(-60px, -60px) translate(0, 0);

        .lxs-svg-icon--is-active & {
            transform: translate(60px, 60px) translate(-60px, -60px) translate(8px, 8px);
        }
    }

    #el_G0nNJcCLjC_an_kmayPM912 {
        transform: translate(68px, 60px) translate(-68px, -60px) translate(0, 0);

        .lxs-svg-icon--is-active & {
            transform: translate(68px, 60px) translate(-68px, -60px) translate(-8px, 8px);
        }
    }

    #el_nDfD72sddD_an_DdcEnEHsU {
        transform: translate(60px, 68px) translate(-60px, -68px) translate(0, 0);

        .lxs-svg-icon--is-active & {
            transform: translate(60px, 68px) translate(-60px, -68px) translate(8px, -8px);
        }
    }

    #el_EryYtdQXnn_an_DiKJea5DL,
    #el_JAJ0s_BWb8_an_AhKs_GDz1 {
        transform: translate(68px, 68px) translate(-68px, -68px) translate(0, 0);

        .lxs-svg-icon--is-active & {
            transform: translate(68px, 68px) translate(-68px, -68px) translate(-8px, -8px);
        }
    }

    #el_djo-q9OiNNB_an_-Rlc-BjL6 {
        transform: translate(68px, 20px) translate(-68px, -20px) translate(0, 0);

        .lxs-svg-icon--is-active & {
            transform: translate(68px, 20px) translate(-68px, -20px) translate(-8px, 8px);
        }
    }

    #el_nZhptVjLUN_an_osEx3nf1f {
        transform: translate(20px, 68px) translate(-20px, -68px) translate(0, 0);

        .lxs-svg-icon--is-active & {
            transform: translate(20px, 68px) translate(-20px, -68px) translate(8px, -8px);
        }
    }

    #el_QpqSs2t2m- {
        opacity: 1;

        .lxs-svg-icon--is-active & {
            opacity: 0;
        }
    }

    #el_dVYBBSUu5_ {
        visibility: hidden;
    }
}
