@import "../../../../utilities/variables";
@import "../../../../colors/index";
@import "../../../../typography/index";
@import "../variables";

.lxs-main-menu-mobile-search {
    margin-left: $lxs-grid-margin-width-mobile;
    margin-right: $lxs-grid-margin-width-mobile;
    font-size: 21px;
    border-radius: 40px;
    padding: 1rem 2rem;
    display: flex;
    background-color: $lx-blue-opaque-75;

    &__button {
        background-color: transparent;
        border: none;
        display: flex;

        svg {
            padding-top: 2px;
        }
    }

    &__input {
        border: 0;
        background: none;
        outline: none;
        font-size: 21px;
        padding-left: 4px;
        color: lx-color("smoke");
        width: 100%;
        font-weight: lighter;

        @include place-holder {
            color: $lxs-main-menu-search-placeholder-color;
        }
    }
}
