@keyframes lxs-fade-in-up {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes lxs-fade-in {
    0% { opacity: 0; }
    100% { opacity: 1; }
}
