@import "../../../colors/index";
@import "../../../css-grid/index";
@import "../../../utilities/mixins";
@import "../../Form/variables";

$checkmark-transition-duration: 800ms;

.lxs-account-password {
    &__text-field {
        margin-bottom: 3.2rem;

        span {
            font-size: 1.7rem;
        }
    }
}
