#el_R83X2SrZK * {
    transition-duration: 0.5s;
    transition-property: transform;
    transition-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
}

#el_R83X2SrZK {
    border-radius: 50%;
}

#el_jmHK5trHns_an_anWn31MKH {
    transform: translate(16px, 19px) translate(-16px, -19px) translate(0, 0);

    .lxs-svg-icon--is-active & {
        transform: translate(16px, 19px) translate(-16px, -19px) translate(0, -3px);
    }
}

#el_jmHK5trHns_an_A0eM93gt1 {
    transform: translate(16px, 19px) scale(1, 1) translate(-16px, -19px);

    .lxs-svg-icon--is-active & {
        transform: translate(16px, 19px) scale(5, 0.3) translate(-16px, -19px);
    }
}

#el_1fUqEs7ngG_an_PpSfaeqmh {
    transform: translate(16px, 7.9999px) translate(-16px, -7.9999px) translate(0, 0);

    .lxs-svg-icon--is-active & {
        transform: translate(16px, 7.9999px) translate(-16px, -7.9999px) translate(0, 8px);
    }
}

#el_jmHK5trHns_an_39zHbxqec {
    transform: translate(16px, 19px) rotate(0deg) translate(-16px, -19px);

    .lxs-svg-icon--is-active & {
        transform: translate(16px, 19px) rotate(45deg) translate(-16px, -19px);
    }
}

#el_1fUqEs7ngG_an_4pOv3FSUw {
    transform: translate(16px, 8px) rotate(0deg) translate(-16px, -8px);

    .lxs-svg-icon--is-active & {
        transform: translate(16px, 8px) rotate(45deg) translate(-16px, -8px);
    }
}

#el_1fUqEs7ngG_an_teAff_UH2 {
    transform: translate(16px, 8px) scale(1, 1) translate(-16px, -8px);

    .lxs-svg-icon--is-active & {
        transform: translate(16px, 8px) scale(1, 5) translate(-16px, -8px);
    }
}
