#el_fiJb0_03so_an_d4g09L0GY,
#el_6cM7c6walG_an_MXnL7DVSt,
#el_MGRlQzwtu7_an_vSLHRnglV,
#el_FTdRbssb7z_an_xpo5OPl9L,
#el_WTI4cQAjR7_an_wrxlBx6XZ,
#el_mDqDRE3p2q_an_2Vo32rqcj {
    transition: all 500ms;
}

#el_URHPR8IxoN {
    stroke: none;
    stroke-width: 1;
    fill: none;
}

#el_fiJb0_03so_an_d4g09L0GY {
    transform: translate(5px, 5px) scale(1, 1) translate(-5px, -5px);

    .lxs-svg-icon--is-active & {
        transform: translate(5px, 5px) scale(3, 1) translate(-5px, -5px);
    }
}

#el_szQRTcTe3W_an_EfeKoG6PV {
    transform: translate(5px, 11px) translate(-5px, -11px) translate(0, 0);
}

#el_6cM7c6walG_an_MXnL7DVSt {
    transform: translate(11px, 5px) translate(-11px, -5px) translate(0, 0);

    .lxs-svg-icon--is-active & {
        transform: translate(11px, 5px) translate(-11px, -5px) translate(-6px, 0);
    }
}

#el_MGRlQzwtu7_an_vSLHRnglV {
    transform: translate(11px, 11px) scale(1, 1) translate(-11px, -11px);

    .lxs-svg-icon--is-active & {
        transform: translate(11px, 11px) scale(3, 1) translate(-11px, -11px);
    }
}

#el_1ciB9_igbX_an_tTT5R1fzI {
    transform: translate(17px, 5px) translate(-17px, -5px) translate(0, 0);
}

#el_FTdRbssb7z_an_xpo5OPl9L {
    transform: translate(17px, 11px) translate(-17px, -11px) translate(0, 0);

    .lxs-svg-icon--is-active & {
        transform: translate(17px, 11px) translate(-17px, -11px) translate(-6px, 0);
    }
}

#el_8bwC8GKAPo_an_Ekwnf6IMO {
    transform: translate(17px, 17px) translate(-17px, -17px) translate(0, 0);
}

#el_WTI4cQAjR7_an_wrxlBx6XZ {
    transform: translate(5px, 17px) scale(1, 1) translate(-5px, -17px);

    .lxs-svg-icon--is-active & {
        transform: translate(5px, 17px) scale(3, 1) translate(-5px, -17px);
    }
}

#el_mDqDRE3p2q_an_2Vo32rqcj {
    transform: translate(11px, 17px) translate(-11px, -17px) translate(0, 0);

    .lxs-svg-icon--is-active & {
        transform: translate(11px, 17px) translate(-11px, -17px) translate(-6px, 0);
    }
}
