@import "../colors/index";

body {
    background: lx-color("smoke", "light");
    min-height: 100%;
}

html {
    height: 100%;
}

* {
    box-sizing: border-box;
    font-variant-ligatures: none;

    &::before,
    &::after {
        box-sizing: border-box;
    }
}

img {
    max-width: 100%;
}

input,
textarea {
    font-family: inherit;
}

button {
    padding: 0;
}
