@use "sass:math";
@import "../../colors/index";
@import "../shared/variables";

.lxs-tool-tip {
    $toggle-icon-size: 16px;
    $popup-border-radius: 3px;
    $gap: 12px;
    $triangle-size: $toggle-icon-size;
    $triangle-offset: calc(100% - #{$gap + 2px});
    $popup-scale: scale(0.5);
    $popup-scale-open: scale(1);
    $background-color: lx-color-transparent("storm", "default", 0.95);
    position: relative;
    display: inline-block;
    outline: none;
    min-width: $toggle-icon-size;
    min-height: $toggle-icon-size;

    &__tool-tip {
        display: inline-block;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        padding: $lxs-tooltip-icon-padding;

        &--shadow {
            svg {
                background: lx-color-transparent("white", "default", 0.01);
                box-shadow: 0 0 16px lx-color-transparent("black", "absolute", 0.2);
            }
        }
    }

    &__toggle-text {
        color: lx-color("smoke", "dark");
        font-size: 1.9rem;
        line-height: 1;
        display: inline-block;
        padding-right: 8px;
        margin-bottom: 0;
    }

    &__toggle-icon {
        width: $toggle-icon-size;
        height: $toggle-icon-size;
        cursor: pointer;
    }

    &__popup {
        pointer-events: none;
        position: absolute;
        transition-property: transform, opacity;
        transition-duration: 400ms;
        transition-delay: 100ms;
        transform: $popup-scale;
        opacity: 0;

        &__container {
            position: relative;
        }
    }

    &__popup-triangle {
        width: $triangle-size;
        height: $triangle-size;
        fill: $background-color;
        position: absolute;
    }

    &__popup-content {
        position: relative;
        background-color: $background-color;
        padding: 24px;
        border-radius: $popup-border-radius;
    }

    &--soft-open,
    &--hard-open {
        z-index: 100;

        .lxs-tool-tip__popup {
            pointer-events: all;
            opacity: 1;
        }
    }

    // top only
    &--top & {
        &__popup-triangle {
            top: $triangle-offset;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    // right only
    &--right & {
        &__popup {
            left: calc(100% - #{$lxs-tooltip-icon-padding});
        }

        &__popup-content {
            left: $gap;
        }

        &__popup-triangle {
            right: $triangle-offset;
            top: 50%;
            transform: translateY(-50%) rotate(90deg);
        }
    }

    // bottom only
    &--bottom & {
        &__popup-triangle {
            bottom: $triangle-offset;
            left: 50%;
            transform: translateX(-50%) rotate(180deg);
        }
    }

    // left only
    &--left & {
        &__popup {
            right: calc(100% - #{$lxs-tooltip-icon-padding});
        }

        &__popup-content {
            right: $gap;
        }

        &__popup-triangle {
            left: $triangle-offset;
            top: 50%;
            transform: translateY(-50%) rotate(270deg);
        }
    }

    // top sides
    &--top-right &,
    &--top-left & {
        &__popup-triangle {
            top: $triangle-offset;
        }
    }

    // bottom sides
    &--bottom-right &,
    &--bottom-left & {
        &__popup-triangle {
            bottom: $triangle-offset;
            transform: rotate(180deg);
        }
    }

    // top all
    &--top &,
    &--top-right &,
    &--top-left & {
        &__popup {
            -webkit-transform-origin-y: bottom;
            bottom: calc(100% - #{$lxs-tooltip-icon-padding});
        }

        &__popup-content {
            bottom: $gap;
        }
    }

    // right all
    &--top-right &,
    &--right &,
    &--bottom-right & {
        &__popup {
            -webkit-transform-origin-x: left;
        }
    }

    // bottom all
    &--bottom &,
    &--bottom-right &,
    &--bottom-left & {
        &__popup {
            -webkit-transform-origin-y: top;
            top: calc(100% - #{$lxs-tooltip-icon-padding});
        }

        &__popup-content {
            top: $gap;
        }

        &__popup-triangle {
            bottom: $triangle-offset;
        }
    }

    // left all
    &--top-left &,
    &--left &,
    &--bottom-left & {
        &__popup {
            -webkit-transform-origin-x: right;
        }
    }

    // right overlapped
    &--top-right &,
    &--bottom-right & {
        &__popup {
            left: -3.4rem;
            transform: translateX(2.4rem) $popup-scale;
        }

        &__popup-triangle {
            left: 4rem;
        }
    }

    // left overlapped
    &--top-left &,
    &--bottom-left & {
        &__popup {
            right: -3rem;
            transform: translateX(-2.4rem) $popup-scale;

            &-left {
                right: calc(100% + #{math.div($lxs-tooltip-icon-padding, 2)});
                bottom: -#{$lxs-tooltip-icon-padding};
                -webkit-transform-origin-x: right;
                -webkit-transform-origin-y: top;
            }
        }

        &__popup-triangle {
            right: 4rem;

            &-left {
                left: 100%;
                top: calc(100% - #{math.div($toggle-icon-size, 2) + $gap + $popup-border-radius});
                transform: translateY(-50%) rotate(270deg);
            }
        }
    }

    // horizontally centered
    &--top,
    &--bottom {
        .lxs-tool-tip__popup {
            left: 50%;
            transform: translateX(-50%) $popup-scale;
        }

        &.lxs-tool-tip--soft-open .lxs-tool-tip__popup,
        &.lxs-tool-tip--hard-open .lxs-tool-tip__popup {
            transform: translateX(-50%) $popup-scale-open;
        }
    }

    // vertically centered
    &--right,
    &--left {
        .lxs-tool-tip__popup {
            top: 50%;
            transform: translateY(-50%) $popup-scale;
        }

        &.lxs-tool-tip--soft-open .lxs-tool-tip__popup,
        &.lxs-tool-tip--hard-open .lxs-tool-tip__popup {
            transform: translateY(-50%) $popup-scale-open;
        }
    }

    &--bottom-left,
    &--top-left,
    &--top-right,
    &--bottom-right {
        &.lxs-tool-tip--soft-open .lxs-tool-tip__popup,
        &.lxs-tool-tip--hard-open .lxs-tool-tip__popup {
            transform: $popup-scale-open;
        }
    }

    // bottom left only
    &--bottom-left & {
        &__popup {
            &-left {
                bottom: 0;
                top: calc(100% - #{$toggle-icon-size + $gap + $popup-border-radius + $lxs-tooltip-icon-padding});
            }
        }

        &__popup-triangle {
            &-left {
                top: #{math.div($toggle-icon-size, 2) + $gap + $popup-border-radius};
                left: 100%;
            }
        }
    }
}
