@import "../../../colors/index";
@import "../../Form/variables";
@import "../../../utilities/mixins";

$checkmark-transition-duration: 800ms;

.lxs-password-validations {
    margin-bottom: 3.8rem;

    &__block {
        display: flex;
        margin-bottom: 1rem;
        font-weight: 400;
        font-size: 1.5rem;
        line-height: 2.4rem;
        color: $lxs-form-text-color-light-bg;

        @include lxs-background-dark {
            color: $lxs-form-text-color-dark-bg;
        }

        .icon-checkmark {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 2rem;
            height: 2rem;
            color: $lxs-form-border-color-dark-bg;
            background-color: $lxs-form-border-color-dark-bg;
            border: 0.1rem solid $lxs-form-text-color-light-bg;
            border-radius: 50%;
            margin-right: 1.2rem;
            margin-bottom: 1rem;
            transition-property: color background-color;
            transition-duration: $checkmark-transition-duration;

            @include lxs-background-dark {
                color: $lx-blue-opaque-75;
                background-color: $lx-blue-opaque-75;
                border: none;
            }

            &--valid {
                border: none;
                background-color: lx-color("green");
                color: $lxs-form-text-color-dark-bg;

                @include lxs-background-dark {
                    color: lx-color("smoke");
                    background-color: lx-color("green");
                }
            }

            &::before {
                font-size: 1rem;
            }
        }
    }
}
