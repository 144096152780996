@import "../../styles/module/index";
@import "./variables";
@import "./transitions";
@import "lexus-style-guide/colors/variables.scss";
@import "./mixins";
@import "../../styles/keyframes";

.layout {
    $image-fadein-duration: 1000ms;
    $content-fadeup-duration: 600ms;
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: auto;
    z-index: 0;

    &__content {
        opacity: 1;
        z-index: 2;
        position: relative;
        max-width: $form-max-width;
        padding: 0 $layout-content-padding-sides $layout-content-padding-bottom;
        margin: 0 auto;
        animation-name: fadeup;
        animation-fill-mode: backwards;
        animation-delay: $image-fadein-duration;
        animation-duration: $content-fadeup-duration;
    }

    &__left {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        min-height: 100%;
        background-color: lx-color("deep-blue");

        @include layout-desktop {
            width: $left-min-width;
        }

        @include layout-desktop-percent {
            width: $left-percent;
        }

        @include layout-desktop-right-max {
            width: calc(100vw - #{$right-max-width});
        }
    }

    &__image {
        z-index: 5;
        position: fixed;
        height: 100vh;
        top: 0;
        right: 0;
        width: 100vw;
        opacity: 0;
        pointer-events: none;
        overflow: hidden;

        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: url("../../assets/images/24-07-17_login_desktop.jpg");
            background-position: center;
            background-size: cover;
            transform: scale(1.25);
            filter: blur(10px);
        }

        &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: url("../../assets/images/24-07-17_login_desktop.jpg");
            background-position: center;
            background-size: cover;
            filter: blur(0.1px);
            z-index: 5;

            @include layout-desktop {
                animation: blurin $image-fadein-duration;
                animation-timing-function: ease-in;
            }
        }

        @include layout-desktop {
            width: calc(100vw - #{$left-min-width});
            opacity: 1;
        }

        @include layout-desktop-percent {
            width: 100vw - $left-percent;
        }

        @include layout-desktop-right-max {
            width: $right-max-width;
        }
    }

    .success-tick {
        z-index: 4;
        position: absolute;
        top: 50vh;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &__image-overlay {
        pointer-events: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($overlay-color, 0.8);
        z-index: 6;

        @include layout-desktop {
            background-color: rgba($overlay-color, 0);
        }
    }

    &__image-text {
        pointer-events: none;
        width: 100%;
        text-align: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 6;
        padding: 0 2.4rem;
    }

    &__image-text-1 {
        margin-bottom: 2.4rem;
        color: lx-color-transparent("smoke", "default", 0);
        transform: translateY(1rem);
        font-weight: 300;

        &::before {
            display: none;
        }
    }

    &.transition-in-1 & {
        @include transition-in-1;
    }

    &.transition-in-2 & {
        @include transition-in-1;
    }

    &.transition-in-3 &,
    &.transition-pause & {
        @include transition-in-1;
        @include transition-in-3;
    }

    &.transition-out-1 &,
    &.transition-finished & {
        @include transition-out-1;
    }
}

.is-iframe {
    .layout {
        &__left {
            background-color: rgb(22, 24, 37);
            width: 100%;
        }

        &__image {
            display: none;
        }

        &__content {
            padding: 0 1px 0 1px;
            animation: none;
            max-width: 100%;
        }

        &__primary-nav {
            display: none;
        }

        .lxs-account-back {
            display: none;
        }
    }
}
