#el_7BavNDTjNi {
    stroke: none;
    stroke-width: 1;
    fill: none;
}

#el_sW7LZcG5sm_an_D3PG4V2Eb {
    transform: translate(3px, 6px) scale(1, 1) translate(-3px, -6px);
}

#el_sW7LZcG5sm_an_uuScbWO3F,
#el_Xc_BMUk41G_an_ZY8ZlhzDL,
#el_i97QXjNEbW_an_syzfBZXuo {
    transition: all 500ms;
}

#el_sW7LZcG5sm_an_uuScbWO3F {
    transform: translate(3px, 6px) scale(1, 1) translate(-3px, -6px);

    .lxs-svg-icon--is-active & {
        transform: translate(3px, 6px) scale(1.18, 1) translate(-3px, -6px);
    }
}

#el_Xc_BMUk41G_an_ZY8ZlhzDL {
    transform: translate(3px, 11px) scale(1, 1) translate(-3px, -11px);

    .lxs-svg-icon--is-active & {
        transform: translate(3px, 11px) scale(0.6, 1) translate(-3px, -11px);
    }
}

#el_i97QXjNEbW_an_syzfBZXuo {
    transform: translate(3px, 16px) scale(1, 1) translate(-3px, -16px);

    .lxs-svg-icon--is-active & {
        transform: translate(3px, 16px) scale(1.22, 1) translate(-3px, -16px);
    }
}
