@import "../../../../colors/index";
@import "../../../../typography/index";

.lxs-models-menu-desktop-model-grid {
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    gap: 16px $lxs-grid-margin-width-desktop;

    &__item-first,
    &__item-second {
        max-height: 368px;
    }

    &__item-first {
        grid-column: 1 / 5;
    }

    &__item-second {
        grid-column: 5 / 9;
    }

    .lxs-image-card__tag .lxs-sticker__line {
        color: lx-color("deep-blue");
    }
}
