@import "../../styles/mixins";
@import "lexus-style-guide/colors/index";
@import "lexus-style-guide/utilities/mixins";

.page {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: lx-color("deep-blue");

    /* __content contains header and page content
       it is display flex and min-height 100% so that PageContent can
       take up a minimum of the rest of the screen, if the content isnt larger than that. */
    &__content {
        min-height: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
    }

    &--lock-scroll {
        overflow: hidden;
    }
}
