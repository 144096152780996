@import "../../styles/module/index";
@import "../../styles/mixins";
@import "../../styles/variables";
@import "../SecondaryNavigationMenuMobile/variables";
@import "lexus-style-guide/colors/index";

.header {
    position: relative;
    transition: background-color $secondary-navigation-menu-mobile-duration;
    
    @include section-header-background;

    &--hide-background {
        background-color: lx-color-transparent("deep-blue", "dark", 0);

        .header-primary-and-secondary {
            &::after {
                opacity: 1;
            }
        }
    }

    &--pwa-native-mode {
        background: lx-color("deep-blue", "darker");
    }
}
