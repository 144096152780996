@import "../../../../colors/index";
@import "../../../../typography/index";
@import "../../../../css-grid/index";

.lxs-models-menu-desktop {
    @include lxs-screen(mobile) {
        display: none;
    }

    &__layout {
        &-top {
            position: fixed;
            width: 100%;
            top: 0;
            z-index: 3;

            @include lxs-screen(mobile) {
                display: none !important;
            }
        }

        &-left {
            grid-column: 1 / 10;
            min-height: 100vh;
            color: lx-color("deep-blue");
            background-color: lx-color("smoke", "dark");
        }

        &-right {
            grid-column: 10 / 13;
            background-color: lx-color("deep-blue", "dark");
            color: lx-color("smoke");
            padding-top: 10.7rem;
            padding-left: 26px;
            z-index: 4;
            position: relative;
        }
    }

    &__header {
        grid-column: 1 / 9;
        background-color: lx-color-transparent("smoke", "dark", 0.97);
    }

    &__close-button {
        padding-top: 1.7rem;

        .lxs-svg-icon-button__title {
            color: lx-color("deep-blue");
        }
    }

    &__filter {
        padding-top: 2.5rem;
        padding-bottom: 1.5rem;
    }

    &__body {
        padding-top: 15rem;
        padding-bottom: 8rem;
    }
}

.lxs-models-menu-content {
    background: linear-gradient(to right, lx-color("smoke", "dark") 50%, lx-color("deep-blue", "dark") 50%);
}
