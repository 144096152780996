.lxs-main-menu-desktop-content {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100%;
    max-width: 700px;

    &--list-item-active {
        .lxs-main-menu-desktop-nav__list-item:not(.lxs-main-menu-desktop-nav__list-item--active) {
            .lxs-main-menu-desktop-nav__item {
                opacity: 0.25;
            }
        }
    }

    &--list-item-active & {
        &__header {
            opacity: 0.25;
        }
    }

    &__footer,
    &__header,
    &__body-sidebar {
        transition: opacity 400ms;
    }

    &__body {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}
