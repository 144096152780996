@import "../../Form/variables";
@import "../../../utilities/mixins";
@import "../../../colors";

.lxs-account-back {
    padding: 2rem 0;
    min-height: calc(35px + 4rem);

    &__button {
        background-color: transparent;
        border: 0 none;

        &:focus {
            outline: lx-color("smoke") solid 1px;
        }
    }

    &__chevron path {
        fill: $lxs-form-color-light-bg;

        @include lxs-background-dark {
            fill: $lxs-form-color-dark-bg;
        }
    }
}
