@import "../../../../colors/index";
@import "../../../../typography/index";
@import "../variables";

.lxs-main-menu-mobile-secondary-nav {
    padding-left: $lxs-grid-margin-width-mobile;

    &__list-item {
        margin: 0;
        padding: 0;
        text-indent: 0;
        width: 100%;
        overflow-x: hidden;

        &::before {
            display: none;
        }
    }

    &__item {
        text-decoration: none;
        font-size: lx-font-size("h3", "phone");
        padding-bottom: 2rem;
        color: lx-color("smoke");
        transition: opacity 500ms;
        display: block;

        &:hover {
            color: lx-color("smoke");
        }
    }
}
