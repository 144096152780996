@import "lexus-style-guide/colors/index";
@import "../../styles/mixins";

.toasts {
    $block: &;
    $toast-padding: 2.4rem;

    position: fixed;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 2.4rem;
    background-color: lx-color-transparent("deep-blue", "light", 0.75);
    z-index: 11;

    @include desktop {
        align-items: center;
    }

    &__content {
        position: relative;
        background-color: lx-color("smoke");
        border-radius: 0.3rem;
        padding: $toast-padding;
        width: 100%;

        @include desktop {
            width: 37.6rem;
        }
    }

    &__close {
        cursor: pointer;
        position: absolute;
        top: 1.4rem;
        right: 1.4rem;

        &:hover {
            cursor: pointer;
        }
    }

    &--hide-close-button {
        #{$block}__close {
            display: none;
        }
    }
}
